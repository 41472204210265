<template>
  <div class=""  style="width: 100%; height: 100%; position: absolute;" :style="{backgroundImage:'url(' +bg2 +')'}">
    <div class="na" style="background-color: rgba(255,255,255,0.4); height: 60px">
      <div class="na-left" style="height: 60px; width: 100px;float:left; margin-left: 60px;display:flex; align-items:center; justify-content:center;">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1392 371" enable-background="new 0 0 1392 371" xml:space="preserve">  <image id="image0" width="1392" height="371" x="0" y="0"
                                                                                                                                                                                                                                  xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABXAAAAFzCAYAAACXVCETAAABdGlDQ1BpY2MAACiRdZHLS0JBFIc/
tTBKKahFixYS1iI0eoDUJkgJCyTEDLLa6M1H4ONyrxLSNmgrFERtei3qL6ht0DoIiiKIltG6qE3F
7dwMjMgznDnf/GbOYeYMWKNZJac3DEAuX9QiQb9rLjbvsj9iw4mdPjxxRVfHw+EQde3tBosZr7xm
rfrn/rWWpaSugKVJeExRtaLwpHBopaiavCncoWTiS8LHwh5NLih8beqJKj+ZnK7yh8laNBIAa5uw
K/2LE79YyWg5YXk57ly2pPzcx3yJI5mfnZHYLd6FToQgflxMMUEAH4OMyuzDyxD9sqJO/sB3/jQF
yVVkVimjsUyaDEU8opakelJiSvSkjCxls/9/+6qnhoeq1R1+aHwwjJcesG/AZ8Uw3vcN4/MAbPdw
lq/lF/Zg5FX0Sk1z70LrGpyc17TEFpyuQ+edGtfi35JN3JpKwfMROGPQfgnNC9We/exzeAvRVfmq
C9jegV4537r4BWdZZ+bkU3vRAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAX
cJy6UTwAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAACxMAAAsTAQCanBgAAIAASURBVHja7N13
nCxFuf/xz4ZDBhWFBlQEJRnBdM0JRcUyh1LMWa+K6ZqzP7OoF3P2IoqhjQiNkSSIgCiYCIKKgEop
SJR4zu7vj+pyampnz9kwM9U9/X2/XvPaNNP99JwzszvfefopEBERERERERERERERERERERERERER
ERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERER
ERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERERER
EREREREREREREREREREREVmuqdwFjIKxbjNge2C7RT5OARcCfx/0sSqLK3Mfg4iMn7FuCtiKxZ87
tgOuZZHnDvzzxzW5j0NEREREREREJkfrA1xj3TRwJ+DhwMOAPYAtV7nZK4GzgSOAw4FfVGWxLvex
ishwGevWAPfGP388FNgZ2HSVm70U+B3+ueNw4PSqLOZzH6uIiIiIiIiItFMrA1xj3RbAPoCpL9uN
eJf/BL6PD2N+VJXFZbnvAxFZGWPdNsC++ND2IfiO21E6l16Ye6w6dEVERERERERkOVoT4Brrtgce
jw9d7g9slKmUtcBx+DDmm1VZnJf7vhGR9TPW7U7v+eNu5Hvuuwr4Mf7541tVWVyS+74RERERERER
kWZrfIBrrLsx8DrgJcAmuetJXA98CnhXVRYudzEi0s9Yd0vg7cBTaN7z3WXA+4GPaO62iIiIiIiI
iCymaYHGfxjrtgReDryK0Z/ivFpXAQcCB1RlcWnuYkS6ru7YfxPwfGA2dz0b8A/gncBnqrK4Nncx
IiIiIiIiItIsjQtwjXWbAC8E3gBsk7ueZboUeB++o+6q3MWIdI2xbmvgNcBLWf1iZOP2F+CtwJe1
aKKIiIiIiIiIBI0JcI11s8DTgbcBN89dzypdCLwD+FxVFtflLkZk0tULG74MeDVwg9z1rNIZ+O7h
71RlMZ+7GBERERERERHJqxEBrrFuL+AQ4Da5axmyPwJPrcrixNyFiEwqY50BPg8UuWsZshPwzx9/
zl2IiIiIiIiIiOSTNcA11k0BLwY+AGyc+84YkbXAG4EPVGUxl7sYkUlhrNsIeA/wyty1jNDlwHOr
svhG7kJEREREREREJI9sAW49q/LzwKNz3wlj8iPg6VVZuNyFiLSdse5WwNeAu+SuZUw+A7xCs7VF
REREREREuidLgGus2w34AbBz7jtgzP4GPLQqi9/mLkSkrYx19wcOBbbKXcuYnQo8rCqLC3MXIiIi
IiIiIiLjMz3uHRrr7gr8jO6FtwA7AMcZ6+6buxCRNjLWPR74Id0LbwHuCPzMWLdL7kJERERERERE
ZHzGGuAa6x4CHA3cJPeBZ3QD4EfGusfkLkSkTYx1LwJKYKPctWR0S3yIe+fchYiIiIiIiIjIeIwt
wDXWPQ44HNg890E3wMbAN411z8ldiEgbGOveAnyczAsvNsS2wDHGunvnLkRERERERERERm8sYYix
7gH4mbdd7pxbzPOqsvhc7iJEmspY9zbgrbnraKBLgftUZfG73IWIiIiIiIiIyOiMPMA11u0F/BTY
MvfBNphCXJEBFN5u0N+Ae1Zl8ZfchYiIiIiIiIjIaIw0wDXW7QycAGyX+0BbQCGuSETh7ZKdBdyr
KouLcxciIiIiIiIiIsM3shm4xrrN8DNvFd4uzWeNdc/NXYRIEyi8XZbdgW8Y62ZyFyIiIiIiIiIi
wzfKRcw+DNwm9wG2jEJc6TyFtyvyAOD1uYsQERERERERkeEbyQgFY92TgK/mPrgW0zgF6SSFt6sy
B9y/KovjchciIiIiIiIiIsMz9ADXWHdL4DS0aNlqKcSVTlF4OxQXAHtWZfGv3IWIiIiIiIiIyHCM
YoTCx1F4OwwapyCdofB2aG4GvDt3ESIiIiIiIiIyPEPtwDXW3Rc4NvdBTRh14spEU3g7dGuBW1dl
cU7uQkRERERERERk9YbWgWusm0KdX6OgTlyZWApvR2IWeHvuIkRERERERERkOIY5QmFf4F65D2hC
KcSViaPwdqT2M9bdIXcRIiIiIiIiIrJ6QwlwjXXTqPt21BTiysRQeDtyU8A7cxchIiIiIiIiIqs3
rA7cJwB75j6YDlCIK62n8HZsHmGsu2fuIkRERERERERkdVa9iJmxbg3we2DX3AfTIVrYTFpJ4e3Y
HQs8oCqL+dyFiIiIiIiIiMjKDKMD9xkovB03deJK6yi8zeJ+wD65ixARERERERGRlVtVgFvPvn1z
7oPoKIW40hoKb7PS/S4iIiIiIiLSYqvtwL0zsGPug+gwhbjSeApvs7unsW673EWIiIiIiIiIyMqs
NsB9eO4DEIW40lwKbxvjYbkLEBEREREREZGVUYA7GRTiSuMovG0UPVeLiIiIiIiItNTUSm9orLsp
cEHuA5A+z6vK4nO5ixBReNs4/wZuXJXFtbkLEREREREREZHlWU0Hrk7JbR514kp2Cm8baXPgfrmL
EBEREREREZHlW02Aq1Nym0khrmSj8LbRTO4CRERERERERGT5VjRCwVi3CXAxsFnuA5BFaZyCjJXC
28b7M3CrqizmcxciIiIiIiIiIku30g7c+6PwtunUiStjo/C2FXYG9shdhIiIiIiIiIgsz0oD3H1y
Fy5LohBXRk7hbavouVtERERERESkZVYa4O6Yu3BZMoW4MjIKb1vn5rkLEBEREREREZHlWWmAu33u
wmVZFOLK0Cm8bSU9d4uIiIiIiIi0zEoD3O1yFy7LphBXhkbhbWvpuVtERERERESkZZYd4BrrplAX
V1spxJVVU3jbanruFhEREREREWmZlXTgbgFslrtwWTGFuLJiCm9bTx24IiIiIiIiIi2zkgBXHVzt
pxBXlk3h7UTY2li3ce4iRERERERERGTpVhLgqoNrMijElSVTeDtRitwFiIiIiIiIiMjSqQO32xTi
ygYpvJ04eg4XERERERERaZHZFdxG3VuT5bPGOqqy+FzuQqR5FN5OJD2Hi4iIiIiISOMY67YD3gM8
M/r2KcBLqrI4KXd9Oa2kA3dN7qJl6NSJKwsovJ1YU8a6mdxFiIiIiIiIiAAY6x5irJsH/k5/eAtw
F+BEY928se7TuWvNZSUBrkwmhbjyHwpvJ9oUsFHuIkRERERERKTbjHWzxrp/Aj9Y4k2eXwe5e+Su
fdwU4EpMIa4ovJ18/8pdgIiIiIiIiAhwPXCTFdzujHrcQmcowJWUQtwOU3g78a4DrshdhIiIiIiI
iHSbse6MVW7i78a6jXMfx7gowJVBFOJ2kMLbTrgYmMePURAREREREREZO2PdvYBhjEH4We5jGRcF
uLIYhbgdovC2My7KXYCIiIiIiIh03vFD2s6djXWzuQ9mHBTgyvooxO0AhbedchF63hcREREREZFM
jHXFkDf5kdzHNA56IS8bohB3gim87Rx14IqIiIiIiEhOjxny9v479wGNgwJcWQqFuBNI4W0n/St3
ASIiIiIiItJp++YuoI0U4MpSKcSdIApvO+vM3AWIiIiIiIhIp+2du4A2UoAry6EQdwIovO2sa4Ff
AGuAqdzFiIiIiIiISCdtmruANlKAK8ulELfFFN522i/wIe663IWIiIiIiIiIyNIpwJWVUIjbQgpv
O+94fOftbO5CRERERERERGTpFODKSinEbRGFtwL8HP+crw5cERERERERkRZRgCuroRC3BRTeCnA2
8I/6cz3vi4iIiIiIiLSIXsjLainEbTCFt1I7of44hRYwExEREREREWkVBbgyDApxG0jhrUROAObx
z/l63hcRERERERFpEb2Ql2FRiNsgCm8lchlwOr3uW3XhioiIiIiIiLSIAlwZJoW4DaDwVhLfwQe2
s8BcfZnPXZSIiIiIiIiILI0CXBk2hbgZKbyVxOXA1/CB7TwwU1/UgSsiIiIiIiLSEgpwZRQU4mag
8FYG+Arw7/rzmfqjum9FREREREREWkQBroyKQtwxUngrA1wEfItet23owNXzvoiIiIiIiEiL6IW8
jJJC3DFQeCuLOBi4Fh/gTteXeTQ+QURERERERKRVFODKqCnEHSGFt7KIvwFH0Atv5/GLl4UgV0RE
RERERERaQi/kZRwU4o6AwltZjy/gA9uwYFlYvCwsZiYiIiIiIiIiLaEAV8ZFIe4QKbyV9fgTcBQ+
wJ0H1rFwbILGKIiIiIiIiIi0hAJcGSeFuEOg8FY24BP0um/jkQlT6DlfREREREREpHX0Yl7GTSHu
Kii8lQ34JnAasAYf4obxCdALc2dWtGURERERERERyUIBruSgEHcFFN7KBpwFfJ7ejNsZemMUgnn8
875GKIiIiIiIiIi0hAJcyUUh7jIovJUNuAp4F3B99L15emMUwkcFtyIiIiIiIiItowBXclKIuwQK
b2UD5oH3Axfig9qp+nuz9efha+ifiSsiIiIiIiIiLaAX8pKbQtz1UHgrS/BR4AR8SBvPvZ2PLmHu
7TywLnfBIiIiIiIiIrJ0CnClCRTiDqDwVpbgEKCif7Zt6Lador/jNnytRcxEREREREREWkQBrjSF
QtyIwltZgiOAL9afz9LrtA1jFKD3HB86cTUHV0RERERERKRlFOBKkyjEReGtLMlRwCfozbiF/k7b
MEYhjEuYSX4uIiIiIiIiIi2hAFeaptMhrsJbWYKjgQ8Ca+kPY2fwM3DD2IR4IbO56HoKcEVERERE
RERaRAGuNFEnQ1yFt7IERwEfwgeyoas2PI/P0Qts56PbTEffn6Y/zBURERERERGRhlOAK03VqRBX
4a0swdHA/+LHIsRB7KAFzKaj64RFy2bQHFwRERERERGR1lGAK03WiRBX4a0swdH0Om9n6+/N1pcQ
0IZgNoxMiOfgzkffg/4OXRERERERERFpMAW40nQTHeIqvJUlOAbfeQu9ADaEtqELN34un6J/ZMJs
dJv5+qM6cEVERERERERaQgGutMFEhrgKb2UJjsGHt3P0xh+Ez0MIOxV9PUuvuza+fujODXNzRURE
RERERKQl9EJe2mKiQlyFt7IEx9ALb6fpH5VA9PkU/aMT4g7b0HEbgt1wEREREREREZGWUIArbTIR
Ia7CW1mCY4EPs/jc2jAiIf5Z2pE7E30egt1ZRERERERERKRVFOBK27Q6xFV4K0twDHBg/flM8rMw
15boY/y9WfyiZeF7oXM3bCcOekVERERERESkBRTgShu1MsRVeCtLcCzwEXqdt+ESgtg4fJ0f8P15
+scphO+FLtxB2xERERERERGRBlOAK23VqhBX4a0swU/xYxPCqIR19ce0eza9hCA3BL1hTMJ0dLt5
YA0+xJ1Dc3BFREREREREWkMBrrRZK0JchbeyBHF4G0LYuEs2nXcbAtsQzobwNu60TTt455Pvi4iI
iIiIiEgLKMCVtmt0iKvwVpbgp8BHWRiqhhA37rwN4W7ooA0jEYiuF3fgTtEbmRBfRERERERERKQl
FODKJGhkiKvwVpYghLfr6HXIwsIFygbNrp1OPg+XMD83HZMQtqUAV0RERERERKRFFODKpGhUiKvw
VpbgOHx4G3fYxt20aSgbB7xEP5tKfjYz4OdxcKvnfREREREREZEW0Qt5mSSNCHEV3soS/BT4WP35
oDm10/QWMZujF8qGTl2in0F/5248ViFsP4jHKYiIiIiIiIhICyjAlUmTNcRVeCtLcBzwCWAtC4PU
qehjCGHjmbZpQDtD/7gF6C1kFr4Xd+ES/UxEREREREREWkABrkyiLCGuwltZguPpjU0IHbfx4mLz
+FA2DmChfwTCFAvHIaTP5eH6c/SHxKGbN52PKyIiIiIiIiINpQBXJtVYQ1yFt7IEx+PHJsQza+NF
yuIQd5b+5+c43I1D2xDEhiAYFnbdzkXXnUELmYmIiIiIiIi0yuzqNyHSWJ811lGVxedGuROFt7IE
x+PHJoQwNQ5WQzgbB62wsDMXeh20U8n1ZvDzccPt40XN5qPbpR250nHGullgywGXzfD/V9YBVwJX
JJd/V2XRyXEcxrppoAB2BLYD/gWcD/y1Kovrc9cnzVH/X9mchY+vLeidDXEVCx9fV1RlsTZ3/WO+
nwrg5sD2wCX0HlPX5a5PREREpAkU4MqkG2mIq/BWluB44FP15+EFe+iYjYPWeOGxOIQN143HIkAv
3J2jF+ZOJ1+HwHYNCm87yVi3BbAbsHt0uSWwA3BjYNNVbPtK4B/AX4GzgbOiyx8nKcw01t0aeDLw
OGAX/GMqNW+s+ytwOPBV4PiuhtxdUQePN6f/8bUbPtzfHthqFdu+GnAs/vi6Nvfxr/K+uzWwH/4x
tSuLP6b+Ru8xdZweUyIiItJVy34xb6z7H+ADuQsXWabnDTvEVXgrSxDC2/gFZzybdrEuXFgY8sZf
zyfbmR9wuyCeexs6Kn9UlcUlue8cGS5j3U7AvYG7ArfDh0k3zVTOOuBP+LDpNOAX+EDzX7nvp6Wq
w7nnAC8G9lzBJi4Avgi8tyqLK3Mfj6xO3a1+J+BewF3wj7FdWcWbIKswB/wZOAP4JXASPtxs9P+z
+jH1bPxjaq8VbOKv9B5TV+Q+HhEREVkZY91a+huYVq0qi4lvVlKAK10ytBBX4a0swc+AT+KDrHjh
sXj0wTwLu23j+bhpt248BiEeuRA+hjEK8fbDz+Lb/bhNQZoMZqzbHngAsHd92Tl3TRswD5wKHFVf
jm9qCGOsux3wGeAeQ9jcecCLq7I4PPdxydLVYePt6D2+7scqOmrHYC1wMr3H18+rsrgmd1GBse62
+MfUPYewufOBl1Rl8b3cxyUiIiLLpwB3ZRTgStesOsRVeCtLEMLbuBs2dOHGnbLh56EzNv5Z3HUb
umjj7bDI7afphcbTyX7DPn+gALd9jHWbAA8E9sUHSrfOXdMqxYHT4cAvcp8eXYd2bwdex/DHTH0T
/zvo0pzHKIsz1m0NGOAR+DdHbpK7plW4Fv+76PvAd6uyOCdHEfVj6q3A6xk8JmE1vg08V2eUiIiI
tIsC3JVRgCtdtOIQV+GtLEEIb9Pn13jcwQw+vJqOfp6GtfH1w23C59A/diGdrRsvfhaHuKGOHyrA
bQdj3Qxwf+Dp+FmRm+euaYT+ChwCHFyVxe/HvfM6aPoc8KwR7uYUYB+FuM1hrNsYH9o+rf447JCx
KX4BfAn4WlUW/xzHDuvH1KeB545wN7/EP6YU4oqIiLSEAtyVUYArXbXsEFfhrSzBCfQWLAsjDWbr
j9A/GiFekCwOZUN4GwLcdMZt6LSNRyik4xlm6uvF2yXazhEKcJvNWHcbfGj7VPLNsc3pV8DBwFer
svjHqHc2pvA2UIibmbFuCrg7/jH2ROBGuWsao7XAEfgw9/BRjVkYU3gbKMQVERFpEQW4KzPs0wNF
2uKzxjqWGuIqvJUl+BnwWRYuPga9DtkQus7hn3/jTty4ozbcJg55QxgcrhsvTEZ0m3X0z90NP48X
PZMGMtZthQ+UngncOXc9md2pvnzQWPcD4PPAYVVZrB3R/j7KeMJb8Atg/chYd5+qLK4d0z6F/4xI
eDbwfPwCZF00CzyyvlxqrPsy8PGqLM4c8n4OZDzhLfjny/CYaszcXxEREZFhUoArXbakEFfhrSzB
CfiAaV30vbTbNnwMc2qpv447dOcYHOZOJ9udG/C9tfSHv3G4u46FXbrSEMa6HYD/wYdKW+Sup2Fm
8Ke1G+A8Y90HgM9VZXH1sHZgrNsHeNGYj+uu+Dm7bx/zfjupXvDvVcALgc1y19MgNwReArzEWPdd
4F1VWZyy2o0a6/YG9h/zsdwFP2dXf691SN1NvzGwJf7355b112uBK6LL1VVZ6O8f6ZR6DNcNgY3w
f09ND/i4DrgMuLgqi3Ur25OIjItGKIisZ5yCwltZghPwnbfpHz3x82vcjRsHumkQG643hX/xAf3z
bePFzUKQG8+9DbNy55LtraN/3MLhGqGQn7FuJ+C1+I7AjXLX0yL/AD4IfLIqiytWsyFj3abAb4Fb
ZTiO64A9R9D5KLX6MfYa/GNs49z1tMQP8UHucSu5cb3Y4m/I0+F8Pf4xdUaGfcuQ1eHsTYDdo8tu
wC2ArfFh7ZYsrSFpjl6YeylwAfAH4Kzo4wUKeaVNjHU3BPYAdgRuFl1uXn/cnqWfoj4H/BNwyeWv
wJnAaVVZ/D33Mcvk0AiFlVGAK+ItCHEV3soS/Bwf3q6l93wagtV4/EEa3sYB7jz+xUd8mxC2xoFs
2skbL3AWd/emi6HNR/sOqqosLs5953WVsW53fKfYUxnyHy4dcwnwEeAjK31Dwlj3bvy/RS7HAg9Q
aDBcxrrd6D3GdLbZyhwHvBP48XL+fxrr3gG8KXPd96/KYm7VW5KxMtbtDOwN3AO4LT6wHed86qvw
Ye7pwMnA0cDv9H9JmsBYtxGwJ3C36DLuN8occBpwavTxHD1GZCUU4K6MAlyRnlcBH8d3wr0JeHXu
gqTRfo5f9CgEqMEU/QuNpSFq+rwbd8nG82rDWITpZNvxtuKO3HQxsxD+xvWEnx2uAHf8jHW3wgci
T0SziIfpSuDDwAFVWVy21BsZ67bEd/Nukrn++1dlcWzmGiZCPSrh/+E7bqdXuTnxjgNeXZXFSRu6
orFuC/wL/NxjKvauyuLozDXIBhjrbgY8AB/aPgDfWds0F+OD3HA5U2+4yTjUz6cPAu6PD2vvSDPP
JLkUOAao8Isk/y13QdIOCnBXRl0JIj0fAN6A/+W4ee5ipNF+Tv/M27B4WDp7dhYfwoZfTnF3bNx5
G49OCO9ih/A2vFCIu2unkv2FnxP9bA29IDfM2Y0XNZMxqf8IfyvwcvR7dxS2AN4IvMBY91rgoCV2
gzya/OEtwH74TlxZIWPdLPBi4B34U6pleO4DnGis+xzwug28+fdI8oe34B9TCnAbph6JcHfgScC+
tGMhwRsDj68vABca634IfAP4UVUW1+cuUCaHse5GwMOBxwEPoRl/o2zIDfF/Tz26PoZT8WFuBfxC
c3VFhksduCIiy3Mi8AV6M2rjhcLCuIO48yseiRCuH48+mEluG36eLlY2x8KFyKboD3TT/cbP8aHL
F+AwdeCOXv1i9bH41dhvlrueDjkBeFFVFr9e35WMdUfgQ4Tc/gVsX5XFdbkLaSNj3T2AT+JPLZXR
uhi/+N4XBr1JYqw7DB8+5HYJsJ0eU81Qn33y1PqyS+56huifwFeBg4FfqTNXVsJYty0+/Hws8EAm
643+i4Dv4l83najHiMTUgbsyCnBFRJYujE1IZ9sG8YzaeLTC9CLfj8PXeHxCEN8+dOrGQW+47lxy
m7C/Ofrn64aff08B7mgZ63YBPobvoJDxWwd8FHhrVRaXpz801t0E+DvNeaH08KosqtxFtEn9b/he
4Dm5a+mgE4H/rsritPANY93W+PEJTXlMPbIqi8NyF9FVdSehBZ4G3Ct3PWNwBvBl4MtVWZyXuxhp
NmPdDP7NrhfhxyR0YeTPb4HP4B8jl+YuRvJTgLsyTfkjS0Sk6U4E/o/eqAPozbiNw9UQnMaLmqWm
o4/pKIQp+mfihu0MGpcQby+dgTvF4LB34n+x5VSvwP66+tLEWWVdMYMfWfFEY90rgDLp/LgDzfob
6M740w1lA+rO9ifjF7DbOnc9HXV34JfGuv8F3lyVxdU08zGlAHfMjHW3xK8h8Qxg09z1jNGtgXcB
7zDWHQq8pyqLX+QuSprFWLcV8CzgpcAtc9czZrfHv7H+fmPd1/Fhrrpyx8BYd0P8OKRd6ssWuWuq
DX0hZ2NdU/4/XYT/O/Vs/CiRPw5rw036Q0tEpKnC2IQQ1IYu11n6g9v4Z2H8QRz2xuFp3Hmbdu7G
YxemWdhBGwe2cwOuFz4n+jrer4yAse4uwBeB2+SuRf5je+BrwH7GuhdUZeHq7++Yu7DEzXMX0Ab1
i5DP0ptHKflMA/8DGGPdE2ne/+Gm1TPRjHW3AV6Pnz889BflLTINPAZ4jLHux8C7gWMVUnWbsW5n
YH/8GSNb5a4ns02BZ9aXXxvr3ot/k30paxfIMhjrdsQ3MzyP5oS2XXET/KK6wH9GTB1QlcVxq92w
AlwRkfU7CR/eQv8sW1g4uiB0vcbzaOMgN9w+DnrD9uJZuETfj4PdtJN2Hb0FyhYLeePrTrEwSJZV
qjsCXw68H/1ebapHAXcz1j2lKoujaF6407R6Gqd+g6QEds5di/TZA/978u+5C0noMTUG9ePyDfjQ
UvrtU19+bqx7F3CEgtxuMdbdEb/I6mPoxpiE5doTP0f6Dca6twCH6jEyHMa6RwNfoVtnQjTZI4BH
GOsOBF61msX99EQiIrK4k4DPs3BmbRB32MajD+IRCUQ/jz/OLHL7OKwNnb0zyffn6M23nY9uF7Yx
E22f6HtTKGAcqnru43eBD6H7tum2A35irHsbcIvcxSQUNi3CWDdlrHspfnE6hbfNtAnN+7fRY2qE
jHW3rxeC/AUKbzfkHsDhwK+MdQ/OXYyMnrHuZsa6LwK/Ah6HMpcNuT3wHeBkY91D68YIWSFj3cuA
b6PwtoleDnzLWLfifxs9mYiIDHYyfubtoBEH6SiENEAl+RhC2GBmwO2JthGHrzPAWnpBbdhm/Pw9
TX/gG0Je6IW8JNuVVTLW3R04FXhk7lpkyaaAt9K8ha90atsA9ciEbwEfBtbkrkdaRY+pETDWbVXP
Pj4V2Dd3PS2zF/BDY9236lObZcIY6zY31r0d+APw9Nz1tNBdgO8Dxxnr7pe7mDYy1j0IOBCdbdlk
j8LPTF8RdQuJiCx0EnBQ9HW8OFkatoYZtPF1w/VCN27cwRvPqx20vXQxMvDBxXz0s3TGbTwzNw2X
5wdcT1ah7gx4JfBe9HtUZCSMdXfAd7c3rbNTpHPq33v7AR/En80gK/dY4KHGuncAH6rK4rrcBcnq
GOum8YHtu/Gz92V17gUcY6z7BvDSqiwuzF1QGxjrNgM+nbsOWZKXGeu+VpXFycu9oV7Ii4j0Oxm/
EFU8myZeHCztsA2jCeIxByFgDcEr0W1D923aaRu+F28jjEKI5+imc2yno0s8Zze+PvR3CssKGes2
B74BfACFtyIjYawzaGSCSCMY624LHA0cgsLbYdkMeA/wm7pjTlrKWHc3/CiR/0Ph7bA9ATjDWPds
jVVYkhcDt8xdhCzJNP615IpuKCIiXghv5+gPWuOO1/Rn0N9lGwepYZGxEMKm3bFxV2wcrs5E+wrX
C4uQzSf7jRdSC8/pcaduHC6ni7DJMhjrtgWOxc8zE5ERMNY9EzgU2Dx3LSJdZqxbU3eJngbodObR
2B34sbHuEGPdjXIXI0tnrNuoXpzuBOBOueuZYDfEr0fyE2PdrXIX03CPyl2ALMu9jXU3We6NFOCK
iHi/AA6uPw/dr/GiYvEiYHGQOih0jUPSuWibsDCojcclxOHwPL35tXEtacctyXVm6e/8DT9P65Vl
MNbtDPwMuHPuWkQmUb1Y2WvwXUwzq92eiKycsW4n4DjgTehsk3F4MnCase4euQuRDTPW7YEPbt+A
8pRx2Rv4rbHuVcY6PScljHU3xi+YKO0xBTx0uTfSE46ISK/zNgidqvH82LhjNr5O3PUagt8p+scl
EF0nBLyhK3c2ut5MtN+4Wzb9XhwAz9I/ozcNgoO4dlkGY92e+D/Ud8ldi8gkqucHfgB4X+5aRLrO
WPcY/CJld8tdS8fsiF+86TX1c6I0TP1G438Dv0Jv6OewKXAA/nGyU+5iGmY3lO210W2XewP9I4tI
1/0C+DILO2dhYddr3L0arruOXmibXtI5tLPRdkPIGo9eWGy8QRiDQLKvddHP027h+BjSrl114S5R
vQruT9HcP5GRMNatwb+B9srctYh0mbFuY2PdR4Bv409blvGbwb+RdbixbpvcxUiPsa4ADgM+gQ8S
JZ+7A7801u2TuxCRcVOAKyJddgq98DbtqA1BZzzmYHqRSwhNQ/cr9ObXztLfvRuPQwhfx99fbFxC
HNDCwpEI8Yzb+aj2NHxWeLtEdRfSD4GtctciMonq8PZrwFNz1yLSZfVsyROA/XPXIgDsix+pcN/c
hQjU/w6/AUzuWuQ/tgZ+YKx7vRY4ky5RgCsiXXUK8CV64S30z58NHwd13UJ/wDpDf6A6w8KxBiFM
TbcRh8dxUByHr+ltwAfDabA7qOt3KqohXF82wFhngW8AG+euRWQS1acIfwl4bO5aRLrMWHd//N9E
WoipWXYAjjLWPS93IV1mrHs68BNg29y1yALTwLuBbxvrut5scTZaqLqNzljuDfRCXkS6KIS36+gF
nCH8BL8IGPQHq+lYhDg4jX9hhhB4Kvp5GJmQBrJxcDwoXE33Ewe8cd1xrXEHbxwQzyTXl0UY6x4B
fAUtpCQyEnW3zIeBJ+auRaTLjHWPx59pcsPctchAM8BnjHVvUZfheNXzbt+GH/GzZpWbk9F6NHCy
se42uQvJpSqLi4ATc9chyzIPfH+5N9IKfiLSNb/Eh7fx2INZYC29ebZxV24cwq5jYXgaX9J3Pqfo
/dG3LtpuHKTGIfBUctu4gzftrJ2P6oD+UHgd/d3A0FswTSHuehjr7gWUKLwVGaU3Ai/JXYRIlxnr
XgR8jPb8TXA9cA5wVn35E3AZcEV9ubL+eBX+7Jkto8sW+HFIt8Av9rN7/XHz3Ae1RG8HtjfWvaQq
i3Wr3pqsl7FuY+BztGu8zzzwZ+D8+nJBfTkfuBC4Fv8aYF3y8YZAMeCyG3B7YKPcB7ZEuwMnGuse
U5XFkbmLyeR7wD1yFyFL9vOqLP653BspwBWRLvklcDD9i4DFnbVxp2wIOkN4Os/CTti0QxYWBrTz
yTagfyZtvN0QJseBbpijO8fCjlvo76wNgW/atTuPOnA3yFh3O+BwYJPctYhMqvp04HfkrkOkq+pO
znfg30hpqjngV8DRwHH400zPrcpi7ZDvh5sCe+BDjwcA96S5o5NeCGxrrHtKVRbX5C5mUhnrtga+
AzR9/rADToouv6jK4vIh3xdr8I+PO0aXu9Hcv5O3BCpj3eOrsjg8dzEZfAz/PHGL3IXIBs0Dr17J
DZf9Qt5Y9z/AB3IfsYjIMp0CHEJ/52wIPONuyzR8TRf/igPZNASei66bdsfO1tedp78rFnrhbhwe
p4FvPOYhHtuQhrLx7cI241B4GvhKVRb/yP0P0iTGulvgF3DZIXct0knnVWUx8X9w1wsDfhON8JLR
u6Aqi5vnLqJpjHWzwKeA5+SuZYAz8OMcjgKOq8ri0nEXYKzbhF6Y+yCa2c32U+BROe6fSWes2xH4
Eb6bs2muq2v7Nv4xcl5VFmOfeWqs2xT/+DD1pYl/u6wFnlKVRZm7kHEz1j2UFZyWL2P3saosVrRo
qAJcEemCX+LD23gUAsnnsLBbNVwn7cZNRyCkwWsYxRB+FkLiMIIB+kcghO+F7cYLoIWQOA5h49vH
n8dfk9QS71sBbsRYdxPgeJr5B7t0w8QHuMa6/8IHD03tbpPJogA3YaybAb4MPCl3LRGHnzn/JeC0
HIHU+hjrdsKfRv80/CnlTfEr4IEKcYfHWHdTfLf3zrlriVwFHAF8Czhi2B22q1V3sd8aeDjwDKBJ
M2jngOdUZXFQ7kLGzVj3GuB9ueuQRX0feEJVFv9eyY0V4IrIpPsl8DX87LR0Xm082iDuno0/xqFv
2gEbfx6PURi0YNl8dPvr6+/F83bnGDzmYNBCaWkN61hYd9oxHN/2ywpwvbrb5mjg7rlrkU6b6ADX
WLcdPnDYPnct0hkKcCN10PJJ4AW5a8F3En4LP9LqJ8McizAq9f13V3yQ+1SasejbccBDqrK4Onch
bWes2xY4Fj8uoAlOwi/0eWhVFlflLmYp6sfIPfHPMZbmvFn74qosPpG7iHEz1j0RvwBfU/4dxPsU
sP9qfu8pwBWRSfZL4Ov4U2niIDQEpem82DhknaO/yxV6IWsaiM4ltwnihdIGdfDG3bewcLxDsNiC
Z/OL1DSo5rg7WB24/OePzS/iX5CJ5DSxAW7d9fcjYO/ctUinKMCNGOv+H/DmzGVchX/x+qGqLP6a
+z5ZKWPdVvg5k6/EL/aU0/eAx7UhBG+qeubt0cAdMpeyDj9i6MCqLE7Mfb+sRn2fPg14Ec3oXP+f
qiw+lLuIcTPW7YJ/nnoWzZ1b3BU/Ag4AjlztmSYKcEVkUv0K+CoLZ9rGwniCeJGy9ZnGh8FxYBq+
F982Dm7D5/Gs3HkWzreF/tm4UwNuH2pORz/A4GNMO3nDfg5RgAvGuucDn85dhwiTHeC+FXhb7jqk
cxTg1ox1+wMfyVjCpcBHgY9UZXFR7vtjWOpZoM8GXgPsmLGU/8OfKt6o8RNtUIfxP8F3V+dyBfAJ
4ONVWZyf+z4ZpvoN3P3wfwPcKnM5z+riOAUAY902wP2AXYBdgS1y11SzuQsYoauBDwFn4xcYPH1Y
G57NfWQiIiMQh7fQ32kbd7mCD0fjUQdxNy30d9jG1w/dtmGBskGBbDzvdib6PF4EbSrabxzshgA3
1DwzoLZ07m0c7qaLBMUB8rLfvJs0xro9yfuCVmTiGev2Bt6auw6RrjLW7Ue+33XXAu8HPtC02Z3D
UI8u+Lix7jP4Drd3AzfOUMqzgH8Ar8t9n7SJsW5z4HDyhbdzwGeAt05qU0VVFuuALxvrvo6fkfsW
INcba5811p1XlcVRue+XcavK4p/47u5GMdb9Hnj7EDf5y6os7pL7uEZNAa6ITJpf4ccmxDNroX9m
bRxuhjEEcagaQtQZet21c/jnzHUsnIcLC0PddJEzBmw/7D8Od9MFzdKAdi7Zb7rv6brONLyejbbT
aca6LYGSbs+Fuh44BzgL+ANwEb4LJL5cC2wObIl/t37L+rIDfsG33fFdR51/Q0AWqufefoXu/v/4
J/7xdRbwZ+Ay+h9f/8Y/B22ZXG5K7/G1E929/2SVjHX74OfM5vB94KVVWZyT+34Ytaosrgc+Y6z7
FvAu4PmM/3H7WmOdq8rif3PfH21grJvF/x14n0wl/Ah/Wv/vct8X41A/Rj5nrPsS/vHxDuAGYy5j
Fvi2se4eVVmckfs+EVkpBbgiMklOxYe3cVdsCD3D5+Gyjl5wmna7xiMVwvNkHJDGi4eFbcQdrvGI
hTgYTjtzY/PJvuKaoNeBG4vn6sb7I/rezIDvd1Y99/ZTNGMm17jMAacARwEnAGcA5w5jZl59Cumu
wO2A++LnnO6a+4AlL2PdNH61+9zzIcflCvwCOEcBJwJnVmVxyWo3Wi+yuAtwZ/xja2/gZrkPVprP
WHcr4BuM/7XeecDL8IsvdeoN46osLgZeaKz7PP6U+HF3gn3QWHd6VRY/zH1ftMC7gIdl2O+Z+Jmk
P+ja4wOgKotrgY8a676JH6vyuDGXcAPgCGPd3auycLnvD5GVUIArIpMidN4utvhX6EoN34tHIsQd
tfHP10XfG9TJOxPtK+2uja8/n+w7/Dz+3qDFzuKPabAc33Zdsk/oH73AgP131XOAJ+cuYgzOBQ4F
jgR+WpXFZaPYSX0K6W/qy1cAjHU3Bx4APAh4JOPvspD8XgY8MHcRI/YbfAfXkcApo1hEqCqLa4Df
1Zcv1m9A7YIPch8NPJiOvyknC9XBf8n4n3s/Cry+Kot/574PcqrK4hfGursDL8YvWrPRmHY9hT9d
/Y5VWVyQ+35oKmPd4/Bzi8ftf4E31M/rnVaVxd+BxxvrHgN8HNh+jLvfCfiese4BVVlclfu+EFku
BbgiMglOxXeaxB200AtTQzduGsLCwvm40OukTZ8j44A1fec87ZBNRzXM0Ov8DUKoHILWeXpdveG6
cRA7RW+kQxw2h/3ND9j/FAuPvZOMdbfGv8CcVJfjX7QfDPysKou5VW5vRepFOA4GDq6DhEcATwf2
pf9ND5lAdeffu3PXMSIXAocAX6rK4tfj3nndsXV2ffm0sW57/AIxTwf2zH3nSGN8ELjTGPd3KX6B
oO/mPvCmqGd/fsRYdxz+9/IuY9r1TYCv1uHU0N9Uarv678CDxrzbvwLPrMriJ7mPv2mqsviOse5o
/Kzs541x1/+Ff1PUdrETWtpNAa6ItF0Ib0O3bAhFB3UlrWPhmIJ43EE6IzdcP15YjOg68aiD+PMZ
Bo9BSBcam0v2NV3fNr19HDbHAVhcdxwsxzWEfcX1d069Eu5BwCa5axmB4/HB9GF1R2xj1J0m3wC+
YazbFt/9/DJ8B4RMmLpD9GNM3uPsBOC9wPebFIrUXUwfAj5UL8z4SuAp6I2SzjLWPRF40Rh3+XNg
v6os/pL72JuoKotTjXV3Aj6Nf7NlHO4NvBMtatbHWLcV8B38TP9x+Qbwwqos/pX7+JuqKotLgecb
634CfAG/9sI4PB7/O/ODue8DkeXQaVci0manAt9i4TzbEK7CwsW+4gXKSH4Wvh+6YuOO2dCVm4am
08nPFntejRdLC/uZiT6fZmFX7VSyn3iEQqghHeMQvhfP6Y2DY+hmkPvf+HfcJ8mPgPtVZXGfqizK
poW3qaos/lGVxYH4+cPPwM+Ck8nyGOChuYsYop/gx4HcuyqLw5oU3qaqsvh1VRbPwD++PgNcl7sm
GS9j3W7AZ8e4ywPwv4MU3q5HVRZX4N9YeS5+cdBxeK2xzuQ+9qao31w8CL845Dhcjx/Z9USFt0tT
lUUJ3A1/hsm4vLt+81OkNRTgikhbnQZ8k/7ZsNDrQE3nwYZgMw5h4w7ZuKs2DlTThc7iz8P2wnXi
MDe+XRjHMEOvozeMS0gXNgs1xEHsDAu7gNOO23ixtnR0wiwLZ+52hrFuO/yCFZPie8B/VWXxkKos
fpq7mOWqyuL6qiwOxi98ZvGzRKXljHVbAAfmrmNIjgfuUZXFPlVZHNOmUyyrsvhTVRYvAG6JD3Jb
U7usXDT3dssx7G4OeG5VFq+pV5eXDajKYr4qi8/j51aPZCb9AAfXM+kFXoV/g3EcLgH2qcriC236
3dEEVVn8Hrgr/u/ccdgIOKRekFekFRTgikgbnYYPb2MhdE07VOOu0zhcnae/6zVeGGwu+f7UgG3E
FttW/L0Qrs7jA9VQxwz9Xbbzyfbj8DZIO3XDMc7SPwYi7CPtFO6aA4CtchcxBGcBD6zK4lFVWfwi
dzGrVZXFuqosvoGf1fhixveiVkbjzUDbw4J/As8E7luVxYm5i1mNqiz+Wge5dwN+mbseGbm3MZ45
yNcAj67DSFmm+k3X+wB/G8PutgY+X3efdpax7vaM7038P+Hf/Ds293G3Vb3o7mOAt45pl7cF3pP7
uEWWSgGuiLTNr/FjE+IFx+Lnsln6Z8amC33Fna7pbQd13ZJsY5b+cQYwuLN3KvlZvJ905MNcdJu0
PpKfxTN5Z5P9hmOOP4ZAOMzY7dTzvrHu/sBTc9exSlcDbwD2rMriqNzFDFsd5H4Cf2rjl3LXI8tn
rLstfpZcW80DnwR2r8rii5PUNVW/2XM3/FzUS3PXI8NnrLsd8D9j2NUl+DcRD8t9zG1WlcVvgXsy
njFC+wBPyn3MudTrH3wOWDOG3f0cuHtVFmflPu62q8piriqL/we8ZEy7fJmx7sG5j1tkKTr1Ql5E
Wu804Nv153FnbdxRCws7UBlwm0GX0CEbXyd8Hgeqcciabj+dYRsvPBZ33M4NuG4wG30v7ZqNRzfE
Xb5xF+4M/ceaLn7WCca6jYCP565jlX4I3KYqi/dUZTGu2XlZVGXhqrJ4OnB/4I+565Fl+RDtXRj3
b8ADqrJ4UVUWl+QuZhTqN0k+ie80at3YFVmcsW4a/+bDqB9/f8fPgj4h9zFPgnpu8L2BcZxN8yFj
3Q1yH3Mm+zOe9Q8OB/auyuKfuQ94klRl8XHgWfSaUkbpIGPdjXMfs8iGKMAVkbb4NX712LUsnDMb
Asp47muYOzto0Zn4uS8NQdOO3KkBH0OIGn+dnqIWd8rCwrEN6UiD+OOgzuE4lE1D5pnoNnEInXbz
di3EfTlwm9xFrNA64LXAw6qyODd3MeNUn3p4J+DruWuRDTPWPQg/17GNfgjs1ZXTXauy+BvwQPwK
9RPTZdxxz8AHgaN0KfDgqixOz32wk6Qqi4uBfYEzRryr7fCP+U4x1u3EeEYn/AR4QlUW1+Q+5klU
lcVBwJMZ/HpumLYHPpb7eEU2RAGuiLRBPDYhjAMIM2UHjR2YTn6Whq3xz+L5tPH1ppJ9TA34PIjH
KkzTv5Ba6LZN60s/T+ffTiU/D9+LF0yboX/hsri+QTWtoyPP+8a6bYA35a5jhS7Ar+z9/qosxtF1
0DhVWVwO7Af8N+NbtVtW5i25C1iBOfxYkod1rWOqKou1VVm8GXgIfuavtFTdLXbAiHdzNWCqsvhd
7uOdRHWI+xDg/BHv6kXGujvnPt5xqef+fhrYbMS7Oh4/E1rh7QhVZfF14LHAdSPe1ZOMdXvnPl6R
9enEC3kRabXf4MNb6AWYYRZsOic2nSW7joUdroMWDgvfmx+wjfD9eETDYnNnw/Vmogv0Lyw2T38Y
PMfCcHiWhfN6p5PaSH6Whr3pLNz4el3wesazGvewfR/fEfiz3IXkVq/a/Sng7mikQiMZ6+6LX5Cn
Ta4CHlGPJenkGyQAVVn8GLgL45nDKaPxHmCUp/yuBR6vsQmjVZXF+fizGC4e4W6mgU/WM2G74KmM
/syQU4CHV2Xx79wH2wX17O1nj2FXH+rQ40RaSAGuiDTZb/Azb9M5tfHohBC6Qv9M2vD5oAXFYukc
2tnoe3HIOpPUEC+WFs+fhf4O3vB13A0bB8vxHwmLLZ6WdhinC6bNMbgTOdSZdvVOdIhbd9++MHcd
K/BZfLA0yhdxrVOVxWnAPfAvlqRZ3pi7gGX6F34RpiNyF9IEVVmchw/gT8pdiyyPse4uwPNGvJtn
6bEyHlVZnAk8DBhlGHhXxhOAZWWsuyF+Lvso/R54aFUWl+U+3i6pyuIQ4K0j3s2e+NE0Io2kAFdE
muo3wHfpDyqhvxM17qYN3487XKF/LEE8PiEOZNM5tvHM2BAYr0t+Pp/cPuw/HmsQ1xbXE74fB7aD
wuD5AbXN0x9OxyMi4q7gefpn43apA/cVwKa5i1imdwAvqMpiXe5Cmqg+zX1v4Me5axHPWHdX2jX7
9nz8Ikwn5i6kSaqyuAg/F/cHuWuRZRn1bM/3V2Xx5dwH2SVVWZwMPHfEu3mbsW7j3Mc6Yq8BbjLC
7V+C3mzP6R3AF0e8j3cZ69p4Fp90gAJcEWmi3+LD2zCzNe4ohV6wmgaus/SCzRDuhg7UNJRdR39H
axzCQq+zNV1sjKiWeME0ouvN0N+BCwu7adPO3vj2cdA8tcjP01EJ6czcxWbqTnSAa6y7EfCS3HUs
wzywf1UWb6nKQosKrUdVFlcADwe+lrsWAdrVfXsucK+qLEa9WFAr1acAPxJ/xos0nLHuvxjtmyc/
o70z5FutKouvAZ8a4S52AJ6V+zhHxVi3LfCyEe5iHnhyVRZ/zn2sXVX/rfx84JgR7mY7/BsBIo2j
AFdEmiaEt6GDNB2dkI4OiOe+xuMUBolDzHhbMHhMQdxBGy4zyfXDfqcG3HaKwV2+aZ3pAmuD9hfP
0I1D37SmdPG2+NjDdibZS2jX7NtnVmWhVW+XqCqL64CnAF/IXUuXGetuBzwqdx1L9E/gwfWcSVlE
VRbX4x9bx+SuRTZolOHqRcCT6v8PkscrgFNHuP3XGevW5D7IUR0bo1247C1VWehshczqvwUfC/xp
hLt5lbFux9zHKpJSgCsiTfJb4Dv0h4xpp2oaks5F1087auPvhdvHAWc6mmBQx2vc9Tud1BV3+8bB
cjx/Nr5dmH8bL5wW7zO+ftheuqhaXMNsct30uOL7iaiGiWSs2wJ4ee46luGVVVkcnLuItqkXnnoB
cGjuWjrspbkLWKIrgYdVZXF27kLaoF5J/dHAr3PXIoMZ6/YCHjHCXTytKosLch9nl9WPQwtcMaJd
3AL/Zs1EMdbdFHjRCHdxGPDu3McpXlUWlwBPY/2NO6uxCfDO3McpkprYF/Ii0jq/xQcy6VgCWNhJ
GgevM/R3sMYLiKVBbDw7Nh6PEEYtwMLgN65jnoVdv/E83ng8QjxCYR0LO3PD9kJHbbwwG8m+03ER
6dfhfki7a0OIHB/XJK+6/gJg69xFLNH7qrL439xFtFVVFmuB/YDjc9fSNca6G+BX+G6664HHVGWh
xe+WoV6U56GMtrNJVm6Uo0sOUHdhM1RlcQ7+NPFReYOxbmb1m2mUNwKjmu/7Z+Dp9RvI0hBVWZzA
aOeBP8VYd8vcxykSU4ArIk0Qwts5fOAY/kAatOhWvGhXOpogXgwsbCcOhOfoD25DGBqC2XS+bAhX
ia4/G9USbyOenxvXCv2jGGaSj+H2cWcuyf7jLtq4TqJ9xkFy3NEbvh+OO65/YtQvRNrSFXgQ8Prc
RbRdVRZX4+d2/i53LR3zeNqxSODLqrL4Se4i2qgqiwvxXZ5X5a5Feox1twEeN6LNnwu8LfcxSp+v
A98f0bZ3xXf5TgRj3c6MdgG451VlcWnu45SB3gGcPKJtTwP75z5AkZgCXBHJ7bfA91g4iiAOUdMx
BulCY3EYSnSdOORNg984eF0sWB00czYdzwCDxywMmotLVHscwE4PuF3oyk1HM8QLtUH/8Uzhg+B0
bEPa+TuJC5ntC7RhVtWx+BcCkz6LeCzqU+j2xc9tlPF4eu4ClqBktAsBTbyqLE4HXpy7Dunzakb3
+3v/qiwU2DdI/XfC/sA1I9rF6411k/L34OuAUc31/b+qLI7MfYAyWD2v+6mM7g3H5xjrtsp9nCKB
AlwRyel3+JlSg0LKdBZt/Edm6CIdNCYB+kPX2eT76fiF+PtxqBZvey65XhzuztLrdI2D5Xg2bbh+
3DEcvp6NrhOHzvF2Bo2EmBpw3fiYSL4PgzuaJ8UzchewBP8A9qtP/5chqec1tuGU/tYz1u0E3Dd3
HRvwR/QmyVBUZXEQ8MXcdQgY626IHxszCt+tyuLw3McoC1Vl8UdGN3f19sA9ch/jahnrbsTo3lh0
wP/kPkZZv3rO/atHtPktgWfnPkaRQAGuiOTyO3qLEIVQNQ5XoRdGpsFjPM92Jrl+OkohhKzxbcJ1
425Xks/jfaYLqKVdvPF4hZkB3x90XOsG/CwNeePAOCxYFosXKks7jtP7YNBM34lQ//H+yNx1bMA8
8NSqLP6eu5BJVJXFD4H35K6jA5oelF8HPKEqi8tzFzJBXgyckbsIwTKa+Z5XAS/LfXCyXu8H/jCi
bbfhjIoNeRp+walReEl9po8032cY3Uitl07gzGhpKQW4IpJD6LwNQWXccRsHn+vwp0TFow2gfywA
LOwyDd2scYftdHKduWifM9FlLqllloXjGaB/vm48A3c+2TcsHA0RLy6Wdh+nIexMtM14/m3apRvf
J4PGOMxF9U0aC2yUu4gNeFdVFj/OXcSEewta1Gxk6lNtm/5i/x1VWZyau4hJUpXFv/H/7pP4u6NN
RnWWybuqsjgv98HJ4qqyuJbRhexPNNaNKvwcufr30gtGtPnDgG/lPkZZmvrstleOaPM70/xGEekI
BbgiMm6/AA5n4VzWIHwdz4iNFx9bbBRBugBYOoJhJtn2dPS9tfQvhJaOJ4gXVUtn1KbzdBmwjXhG
blrLUubrDgpr0xEN6+u4jWfnTuI7yE0PlX4OvD13EZOu/uN9P0Ddl6NxN/zCN031B+CA3EVMoqos
TgE+nbuOrjLW7QLccwSbvgT4aO7jkyX5IXDSCLZ7Q/yChW11T+A2I9juHPBajeJpl7pR4ogRbf7l
uY9PBBTgish4HQIcTH8nTxyCQn/wGXffhjEB0OtITUcZzLEwRI1D2DRkjcPYdIYu9DpXQ7cs0ffj
64XvpR2waVdxvP9QazyXN51nG98XIYAl+lna5cuAfaW1rhvBv2s2I3xhOyzrgBdq7u141PNw35i7
jgn1+NwFbMBL6k41GY03AP/MXURHjepNygOrsrgi98HJhtVB4jtHtPk2rCGwmFF13x5SlYVGx7TT
qxnNa537Gutul/vgRBTgisi4HELvj8R4pEAs7pCNA9vws3jG7Sz+F/Sgma5xcBluG3fDhm0PWvQs
DWBnov3EHaxxB+188r31LWKWhsYz9HfLxiMc0oXO0oA63u50UgPJ9tL5vZOi6TM5P1KVxW9yF9Ex
nwR0Gv3wPSp3AevxdY0oGa16DuSoFomRRRjrphlNgHsF6r5tmwo4bQTbfaixrsh9cMtlrNsaP0Jr
2Nahs6ZaqyqL0xndGSNPyn18IgpwRWQcDgGeUZVFHLimQWMcWsYhKiwMRMN119Af8qbjAUL4OWjx
sfj78ezbQTVNDfhZ+BjPu03HOqTXnx5wnbirOA5r4+sOmqsbz8Sdoj/Mnqm3E7qHp6P9pN3ErVXP
PhvVqtzD8DfgbbmL6Jr6eea/0czOoTHW7QTskruORVyHgsVxORg4JXcRHXNv4BYj2O5HtThTu4yw
C3cGeHLu41uBpzCahf2+UJXFH3MfnKzKe/Dj8YbtcbkPTEQBbntdhJ/xcnHuQkQ2IA5vYWEXaBqQ
pt+fTj6H/lAzdKHORt8Pt08/TztVQ/dsCI0X61adZuGCYCFMTTtfF7tOur34+/H35pLthdrShdBC
SBuPSgj3Q+gujscqpEHuJNgD2C13EevxyqosNI81g6osTgI+m7uOCfKA3AWsx/9VZXF+7iK6YMSn
cctgjxnBNq8BDsx9YLIi3wHOHMF2H537wFZgFKHzdeg5rvXqcVrfGMGm9zDWjWLmssiSKcBtp78B
e1ZlYYBbAT/JXZDIItLwFvoX9IoDzhDGhiA1hI5puJsuZBbPv4X+WbFp6BnvPw1kw/7SxczCJV1w
LQ5I4wB2Ntp3GkDPJftclxwPLOzcjUPfdGGytNawGFu8yFl8XPPJ9tvuwbkLWI9TgDJ3ER33FnxQ
Iau3d+4CFrEOeF/uIjrmMOB3uYvokAeNYJvfrspC84xbqCqLOeBzI9j0PY11m+c+vqUy1m2LX1hz
2L5alcV5uY9PVs5Yd1Nj3TOAO49oF+rClawU4LbT96qy+BtAVRaX4efSKcSVphkU3g4yaOxA2nEK
/V2r8TiFePTAoNEF6wuA17FwXEPYdgiS4xrD5zPJduP9pZ/HXcHp8YZ9xyFt6KyNj2UtC2fjktQQ
ao4XSks7i8O2J0VTQyWAd2n14ryqsnCM5oVup9SjSpr6WDukKos/5y6iS+oA6V256+iCei7pKBbN
OTj3scmqfIXhn001C9wn94Etw0MZTUPCqGanyogY67Y01j3CWPdhY93pwAXAQYzuDL3H5j5m6TYF
uO10WfxFVRZXoRBXmmV94e2gztFBc2ahP3hNFzYL4WQ6kgAWhphxQBx33sZBcBymTtNbwTTuXI1v
m3bMzkTXjTtdF7vu9IDtBuvo7xwOXcVTAz6Gbt50xMSgeudZOOu3lYx1s8D9c9exiN8B38tdhABw
AHB97iJabldgh9xFLOK9uQvoqG8AZ+cuogNGMbrk7+j1QqtVZfF34Ecj2HRT36gbxIxgm78DTsx9
YLJ+xro1xrp7Guveaqw7DvgX/m/ulwK3HkMJexnrbpn7fpDuUoA7IRTiSoNsqPM2Dknj4HLQnFro
hZ3pKIN0tEAQB7KwcOxAOvc2DT7jMHSGwQFzuhhbuKTjE0j2G0ZCxMcZd/aGOtbQH7LGgfQ8C8Pu
eH/x2InpZNvpfdtmdwS2yl3EIt5dd6lJZvWpkOo2W52mvqg/piqLM3IX0UX173d1t4/eA0ewzS8v
4cwoab4vjmCbTX2u72OsWwM8ZASb/ozOnGoeY92UsW4PY93+xrpD8ev//Ay/SPC9yXNmocYoSDaT
8kJeUIgrjbCUsQlh1m0IQeNwcQ394WT8eRpAxnNv47mv6eJesXgbg8YjwMLFvmbphaKhE3Y62ecs
vY7deIZvHN7GYW1cV7pIWrzwWBzApiMUwvzcUP9M8nkcjscdudB/vG3V1Bcaf0Szb5vmvUzW4n3j
dr/cBSxCwXxehzAZv0uabBQBrh43k+FQYNiLpN7JWLd17gNbgnsCNxjyNq8Bvpz7wMQz1hXGuicb
6/4POA84A/gI8Ehgy9z1AfvkLkC6SwHuhFGIKxktdeZt3AEbX6B/BEEacsZhbQhU48A3DinTwHJQ
aDtLfwdvHBbPRftPxxNAf3AarhN3wYbANuwjjERYbIxDvM0Q/s4kPyPZZzqHN60zniOcjpFo/QgF
mhvgflbdTc1SlcU5wJG562ixu+YuYIBrgG/lLqLLqrL4K3pcjYyxbmdg5yFv9qyqLLQA3QSoyuJq
oBryZqdo7ht2sVGMT/hGVRaX5D6wrjLWbWase6ix7gPGul8DF+JfVz4TuFnu+ga4fe4CpLsmaTEb
qVVlcZWx7lH4d2dHsXqtSGqp4S0s3hkah59xt1w6rzYEotOLXC8egxCHuyH4nYuuMxd9j2j/8fbT
buFBAXPYf/ox3kccLKcLnMXbnYm2Ge8nXows/nl67NALlEP9aW2tZqzbCH/aVNPM4x8L0jwHo46J
ZatXJb9V7joG+E5VFsPuPpPlOxj9nTkq9x3BNhW4T5Yjgf2GvM37Ad/JfWAb8NARbPPbuQ+qS4x1
M8Cd8H+X7YPvqt4od13LsJ2xbpuqLP6ZuxDpHgW4E0ohrozRcsJb6A8b007W8PN0ca709Oc4dJ0B
1tIfYKaB6Uy0baKv47A4nRk7T3+Im87qTUPi+GdpJ23cJTvD4I7hMIYhhMdxeBvXOEN/QEtSZ/jZ
PAuPb1Dw3EZ3ADbLXcQAR1ZlcUHuImSg7wD/BjbPXUjLjGoV59X6Su4CBPCPq2uATXIXMoHuOIJt
KsCdLKP497xT7oNaH2PdVgy/+/E6dObqyBnrboXPJPbBn0V3o9w1rZJGc0kWk/BCXhahcQoyBssN
b6F/pm26aNhc9HUIMomuDwuD2bRrNQ4/0xECcQfrHP2jHOJQOL5tHAanncJpgBuHqNCbmRuHzWn4
G8/HncGPhQjXJakxDqLnkhri8Dl8jOfyhtnAk+AuuQtYhGYLNlRVFv8Gvpm7jhbaPXcBA6wFjs5d
hEBVFlfiF5OR4dtjyNubB47JfVAyPFVZnAv8ecibbeJzfmzPEWzz2Pq5TIbIWHdjY90TjHWfMdb9
CTgH+BR+AbC2h7fXAxq5IVlMyot5WYQ6cWWEVhLewsJ5tNPJ99Pu0/g6g+biho/htmk3btydCr1x
CDPJtmBhWBu6YuMu2rhzOJhL9hkfYzzvNr5uPBs3li5CFo+OSMPusK90rm1cf/g63m/bRyncJncB
A1xF80877LovAc/IXUTLNPHF/Il1IC/NcDSjWWyr64Yd4J5alcW/ch+UDN2RwHOHuL1tjXU3avA8
2FF0ph+R+6AmgbFuE+Be9Lps70T7X28s5ryqLNSBK1kowO0AhbgyAisNb2FhcJuGk0Q/j8cYpCMX
4sW8iLY1S38oGoelU4v8PF08Le60jYPXuCs23md6NsNMdNtwXHG4G75O59rGQXB8X6QLn0F/p218
u8Xm8w5aCK2tmhgqHaMOjsb7KRqjsFxNfKwdlbsA6dPUf4/WBgfGuk2BHYe8WXWtT6ajGG6AC/55
/8TcB7aIvUawzWEvBtcJxrpp/EizMMf2PnRnnE6ZuwDpLgW4HaEQV4ZoNeEt9AeY6azb9DoheI07
Y+OgMiw4NjVg2+FnRPtKg9M0QI0X/pofUGs8+oDk8/j68RzaeG4t9M9MijuD0w7ZQQu8EW0/HMda
+kdFQH/wHY9niLt220yhkixbVRbXG+t+Cuybu5YW0WNNNuQU4Epgi9yFJLbJXcAq7Mrwf0//KvdB
yUicOoJt7kFzA9xhd+D+qSqLs3MfVFsY63bEh7UPwp950ebn2dU4KHcB0l0KcDtEIa4MwWrDW+if
45p2g8ZhZtxZmi4klm4v7m6NFwGLg99ZfNiZdrLG4wji7YQAOGx/XbKvQWMNBnUJpx3DcSdsHFJD
fyCddijHoewMC0dNpIuaxbWk92VrjagzaRgUKrXDUSjAXRJj3RTNW8TsepobLHRS/cbICcCDc9eS
aNOK5qlhj08AODP3QclI/BH/t+0wX9M38Y07jHUbAbcd8mZ/kfu4msxYd0Pg/vRC26b9TZDDQVVZ
/CF3EdJdCnA7RiGurMIwwtsgDm7j2bGwcKGwODCNhUXHwtzbtEM2DlDD4mjgFwlbR//c3HhcQhzm
ziTbjMc5wOCglejjoLmzaYdwPI837UQmun08HzgNhdNtx/dvOku37YtXjqIzabUuAX6duwhZEgXt
S3cTmtdV+ceqLK7NXYQscDrNC3DbbBQBmgKHCVS/gfInhhusjeINhGG4Db2FfofltNwH1SR1SH53
enNs/4v2v24YprOA/XMXId2mALeDFOLKCgwzvI07X9MxCtP0ulGhPwCNxyaspddpG48FiP/ISAPS
OMydZvBCYOnXYTtxMJp2w0J/l+50VEfcARwffwiBQ1dwOiIhHHO8IFtYiCy+bhr8pp2+cZActh3P
DG6jJnaGHK3FDFrj1/jAvWkrIG+du4ABdshdwABn5S5ABtK/y3Ddasjb+6tmtE+0sxhugDvs/3/D
cvsRbHMUIyhaoz7T5jb05tjeD60TsJg/AY/Rc6nkpndUOqoqi6uARwE/yV2LNN4ww1voDzOnBvws
hLSz9SUOVqF/hEDYxiz9QW8cvsbSRcji7tawnXjbM9H+Q0g7H10n3l4IZNOwF/o7eOcHbGsmqiEO
jNPvx8ccdy6H7a2Jbh+Ht/GM3LYHjU0McE/OXYAsTf08dkruOgZoWqcrNC/kBgWFTaXT84erGPL2
9LiZbMP+9x32/79h2WkE2zwt90GNm7FuB2Pd0411BwN/BX4H/C/wMBTeLub7wF2qsjgjdyEi6sDt
MHXiyhIMO7yFXtAZxhrEHblxF+3a6PtxABmHltDrsg1dqKHrNZ4HG3exxm9cLbYwWfgYd/DOMXjc
QXzbuIM4DZHjxc7SWbnp6IdpFnYEh+2nx5b+LL6f4/tzTXJ/t9UtchcwgF4ct8tZ+E4TWb8tcxcw
gB5rzaR/l+Ea9mNP/z6Tbdj/vk187gfYbsjb+3tVFi73QY2asW5L4L705tgOe47wpJoDvgd8HDiy
Kov5VW5PZCgU4HacQlxZj1GEt9C/EFc8uiBddCsOJAfNxQ3jCsLXaXdteH6LO07jgHQdC+fczicf
4/m208lt44XSwnXiOuJ9ztE/3mDQYmjhunF3bhzqLhYix9I60+OO75+20mndslr691qaJr6I12rh
zXRh7gImzFZD3t4/cx+QjNSw/303NdbNVmWxdvWbGqpth7y93+Y+oFEw1s0Cd6U3x/YeKPNZqguA
H9eXI6uy+EfugkRSejCLQlwZZFThbZAuFhaPUghfh8AyPe0/7bQluW0sDojjObhxwBkHtfG+QlAb
jzRIg+Y4aA3bmKO/ezaMPphn4SiGOICeG7DNdfSH2XEncahxbbKP6eh2U+s5nrZq2mnd6/CrQEt7
6HTvpWligPuv3AXIQlVZzBs78Y1s4zTsx97luQ9IRmoU/75b4ufFN8mwRzv8NfcBDUM9x3Y3eoHt
Axj+m0CT6nLgaPxIyR8Df1CnrTSdAlwBFOJKn3GEt/HogXQcQBxexqFj+IUaL2wG/TNlU+G66SJm
aadrfP24rll6YSgDthN3wsahaghSw+fhGNbUn6djHgaF1mF/cc1h+6GmuAM4DmXjRdpmBtTWZk0L
lf5UlcV1uYuQZVEH7tI07bEGcEXuAkTGYNiPPT1uJtso/n27EOC29l0nY922wAPphbY3z11TS6wF
TqTXZfuLBnaai6yXAlz5D4W4wujD21QIJuPO0nQRsDX0d6fGna5xmAr9owjiIDTtaE3HJQTpOAVY
2G07vZ7bktQSaphl4bGm4xHiEQfx+IM43I3HQ8T316BF3tLxCvGs3jZrWqh0Tu4CZNkmoutmDJr2
WAMFUdINCnBlOUYV4DZNZwNcY91mwL3pzbHdK3dNLXI6vQ7bY6uy0POhtJoCXOmjELfTxhXehhAz
7qSN59nGnbdxiAr9HaXxiIL5aNvxmIP5AftKv473GXfVTiX7ToPfIA5jB3UTk2wv7fyN64mD4biu
dAxEer+k201D4LjWuHu5jZr2oqJpHSqyAVVZzOl07yVp2mMNFETJhDPWbQRsNOTN6nEz2SY+wDXW
bQLcYMibbewfAsa6GeCO9Dps7wVsnLuulriQXmB7ZFUWetNeJooCXFlAIW4njbPzdlBwGc+ZDQFq
3LU6aM5sCDjT8QDpmIMwZmAt/aMJ4u3EowoWq3lQ521cVzx+If75THL7WLwA26Cu4cXC43h8BMn1
SLYVbhf/27Z5jEKjXlQAV+YuQGRE1uQuIHGtTnWUDthiBNtUgDvZJj7ABW48gm02KsA11t2SXmC7
N7B17ppa4t/AsfRC299rjq1MMgW4MpBC3E4Z99iEeIxAHNwG6ViCNPyM577ORN9bbPQALJxXG0LQ
2eS6ITROw9M4KJ5Ktgv9c3JDXWnHbHy7+GPcbTzN4scRh8RzyXUGhb/pSId4obZWduAa6zameaGS
XhiLjMf06jch0nij+H9+Te6DkpG6egTbbNrz7aYj2OY/cx6QsW5rfFAbQttb5qynReaAX9CbY3ui
1qKQLlGAK4tSiNsJ4w5voT90DeFlHCrGnaYhsIwXMkvn5UJ/cBmC00HBcJhHGy+INqi7NR3tEIfO
JNtOg9m4pnCdcMwh6I07btOu38UWVYtD2elkX/Ft41B4ZsB1FusyboOmdYSAAlyZXE0LfdYY6zau
yuLa3IWItIy60SZbF/59R3GMYz2jo25CuCe9ObZ3oaUNFRmcjQ9rfwIcXZXFpbkLEslFAa6sl0Lc
iZYjvA1CiBnPfg3iOa4htF2D/0Mr7kSFXhgbL04Wby8Oewd9nwE1xOFxHDDHtwuB6iz94XIIj8Mf
hXH4GsLjdPxBen/EC5TFHcHp6IRBnb1hW3Gnbqg7DqTbahQdGKulAFcmVRP/b28JKMAVEemWUbxW
Genfw8a6aeD29ALb+9LMv2Ob6CLgSOrQtiqLv+QuSKQpFODKBinEnUg5w9u0czUOSQd1z8YduHMs
XPAM+jtSw6zbNOSMrxd/L+3ATTtup1k4l3Yd/YFoGqQO6uCNRyqk98OgMDudAUx0/fSPzrnoZ7Aw
uI5DZBkuzeSUSdXE+c5b4l/YiYhId4zi7LGh/01srLs5vZEIDwS2HfH9MimuAY6jN8f211VZtPmM
QZGRUYArS6IQd6LkDG9hYVgah4yDulOh15W6hv7u0ngW7Wz0MXw/7sxd7PSrQYHxoFm1U9H2Qqgb
vk47XdO6w+dxzXGH8aBFzuJQNvyRGf+bpfNu43rSn0N/qNtWTQxLR7HgjEgTNLUDV0REumUUr1lm
VrsBY90NgPvTC213H+/d0lrzwKn05tj+rCqLpo1tEmkkBbiyZApxJ0Lu8BYWzqGNQ9g4AE3HKoSQ
dVAgGULYMNIgnh0L/d2+cwwepRAvWrbYgmXh65no67DNtNN2bkCdUwzuxk1rHRSyztO/OFr4Xrx4
WRwIx9JF09pKgZLI+DTx8bZN7gJkofpUYRGRUWnECAVj3Rrgbviwdh/gvxhCENwR59KbY3tUVRY6
m0ZkBRTgyrIoxG21JoS30As2B82AnQauj76ORyfEIwDiLtc0CE07T+NAeB2Du1UHhafxrN24hvjn
4Tqzye2nWBjKpouaLRbwxuMdws/jf7M0yI7D3LSLOR4zEc/2bat/D7h/clOAK5OqiQHubvi5eNIs
O+YuQEQm2ij+dt1g8GqsmwJuTW+O7f3RmVdLdSlwFL0u2z9VZdHmswBFGkEBriybQtxWakp4C/1j
E9KwNO6mhcU7XuOZrnGgGYeUaZg5aIGzeLxC3L0a/qgLYw/mojrW0t+VGwfF8SiHNMSN5/UO2l8c
MMchZTjOtMs3XbwM+u+HmQH1sMjtWqEqi3lj3ZU0KzRtUi0iw9TEAFenpzbTHrkLEJGJNooRWjca
9E1j3fb4+bUhtN0h98G3xPXAz+jNsf1lQ153ikwUBbiyIgpxW6VJ4S30Rh2EoDEEpOnCW3H3LfQH
n3EoGjph19If8sZhbvz5oFEJgxb9im8Xf4w7fmFhx2u4blx7PM92KtlX3NkbguMQFMe1pvN141O/
4sA3DpkHdRq3/d3vK2hWaLpV7gJERqSJpzcqwG0m/buIyCiNYlHNmwEY6zYH7kdvju3tch9si/yG
XmB7XFUW/85dkMikU4ArK6YQtxWaFt5Cr9M0hIqz9MLKONQNYWj4ebw4V7yYWNhmHNoGcXAZjxOY
ivaTdvNC/6laU/QHs/H+007iOOiF/oA4bHfQiIQ41E3HHKQdy/EoibDNONwOwXccOMf3UZtHKEDz
ugJ16nDLGOtumLuGlvhb7gIGUFDYTPp3EZGRqcriOmPdxcCNh7jZTxnrngHcA79IsmzYX+nNsT2y
KosLcxck0jVadEBWpSqLq4BH4Z/IpVmaGN5CrwM3HREQws5ZBo83gF4AGf8M+sPgaXpzbqk/n032
Gy4b0Qs746B0JrqE/cZh8aAxD7CwW3eK/jB1NrlNOs4gDolnkv3G+x608Br0B8tpGDwzYH9t1LQA
d7d6Rpq0h8KmJajK4lLgX7nrSOxkrFPXe/PcPncBIjLxzh/y9tYA90Xh7fpcARwGvBQ/C/jmVVk8
qyqLQxTeiuShDlxZNXXiNlJTw9sgDl7jBcKgNxYh7laNO2PjTtqwjTn6w910xMF88nnY51p6AW+8
r1ADLJybG9cf3y7ukI07ZdPbhvrT0C+EtnFncFxv3Fk7W38dd9XOMHj2bvh+uD/b/rx/ee4CElvg
56P9NXchsmQKcJfuLHx3UlNMAfcBqtyFiGes2wy/KnvTNO3NPhFZnQuAvXIXMeHWASfS67I9uSqL
63MXJSI9bX8hLw2hELdRmh7ehs7SdDGueKGuNIgM3w9BZhq4xp2ogwLceKZtunjYoBm26ffjmuKO
1jD6IYxVCIFtqC8e9RB3xMb7mYv2AwvD4Jlo23Fn76DRE4O6gdPZvk39f7FUTXzHf3cU4LaJAtyl
a1qAC7A3CnCb5F40s4PtstwFiMhQXZC7gAl1Jj6w/TFwbFUWTWuUEJGIAlwZGoW4jdD08Bb6Q8VB
owfiQBb6Fx4L4WgcaMbi8QLxtuP7Y3rANuPvxwFvWmP4GK47Sy8YjkPaNDSNZ+IOGl0z6H6Iv067
a8N11tALikluM2hBt0kYoTDsU+iGYXfgqNxFyJIpwF26s3IXMMDeuQuQPg/IXYCIdEIT//5rI4fv
rv0J8JOqLBSMi7SIAlwZKoW4WbUhvIX+hcDSMDPtVB0U6KYLc8VhZwhTQ9A7x8IgMx55EI9ciLeb
1pIuThYvHBaPSUg7YOMu3rj+sP+19Lp14/snvn24Xno/hTrC1yGoTm8/KGhuc4h7du4CBtgzdwGy
LHfIXUCLNDHA3ctYd+OqLC7OXYgACtRFZDwUNK7MVcBP6XXZ/q4qi/nVbVJEclGAK0OnEDeLtoS3
0D8SAQYHqrFBc2Tjmbgk2wk/T8cPxN2vaYAcd63OJN9Lu1fnkhrS2bewsPs2hKszyW3jIDruso3H
KsQ1rWNhF+1ctK90tASsv2O5jZoYKN0/dwGyNMa6mwG75q5jgKa+MG3i4w3gofjfe5KRsW5b4C65
6xCRTmjq78mmmQNOoTfH9udVWVybuygRGQ4FuDISCnHHqk3hbRBmzMbzZdNu3PD8lHa5xt9LA9E4
HJ6P9jWX/Hw+uW4axsZ1Qn8HbtyZG6Tdwuui/UFvsbTws3hUA/QHveloh3QOcFpHmMG7lv5jiYPc
+D5ouyYGSrsb625alYXm4DZfU0/3burj84/0v/nUFE9FAW4TPInm/d8Qkcn0x9wFNNg5+LD2x8DR
VVlckrsgERmN6dVvQmSwqiyuAh6F/4Uio9HG8BZ6AW4IIeNFw+Ju10ELc6XXZcD1wv0RL1w2vZ46
gunoe+ks3LTLNq51tr7+LL1ANdSRhrLhOulxhY/xImXxtuKQeSb6PHTdhuvG92cc+MbXa7OLgH/l
LmKApgaD0k+ney9D3bVzeu46BniwsW773EUIT89dgIh0xnk08++/HC4GSuD5wM5VWexalcV/V2Xx
bYW3IpOt7S/kpeEU4o5Um8PbEKxC/zzXqQFfx+MQ0g5ZWNh9O0Mv4JyNbg/9c25no9vFwWbYZzyu
YA29kDbUHAe/YdRB/HV8rHEd8ZiI2ehnceiaisPbsP34vomD3nQ2b+jcDXW0eu5VPberiV24CgYb
zlg3hf6dVuLk3AUMMA3sl7uILjPW3Qa4c+46RKQb6r//mvj7aByuxb+Wfh3+eXfbqiyeWJXFZ6uy
ODd3cSIyPhqhICOncQoj0dbwFhZ2y8bixcvimbDx7dIRBnEom4ax8cJd8dzbeXpjDebpH2cQb2cq
qSld6CyebRt354aANw5jp5N9xaFvPGsX+u+b+P6I60/vt0GLrS3Wqdx2ZwH3yF1EYh9j3XRVFk09
FV787NsdcxfRQscAz8ldxABPBz6Uu4gOe1ruAkSkc07Cz0DvglPpzbE9viqLq3MXJCL5KcCVsVCI
O1RtDm9hYfgKC0ckxJ2qceA4KJCMrx+Ht/FCYnP0h7ChqzbuTp1hcFAbh6Xpomdxvel24lm78YJo
6QJu4J+L4+AvXuQsDYXj0Ded0Rt/P14QbSK6byNn5i5ggJsB9wKOy12ILOrJuQtoqaNzF7CIPY11
96nKQo+5MTPWbUYzQ30RmWwn5S5ghM7DB7Y/Bo6qyuKfuQsSkebRCAUZG41TGIq2h7fQH3iGSxzU
xqHn+oJSWLhgWNrxGj7OJj9Pg9C4Y3Y+qSmuMVw/hLNxuJqOYUifX8Ps2mn6w+apAdtJjz8NfMOY
iPh6RPuOx1HEgW58/7bZqbkLWITmQTZUPT5BHYMrUC/O94fcdSzijbkL6KjnAdvkLkJEOmeSRihc
BnwHeDGwG7BTVRbPrcri6wpvRWQxCnBlrBTirsokhLdBCGPTGbdpyBoHqKG7FfrDy3ikQXrbQSFt
et00RJ2hP7RNw9PwszXJbePtxzNtB40vmEk+j+f7poHxmmS78bzbuUW+H/YXFi+DhaMn2uyU3AUs
4gnGuk1zFyED3RO4Ze4iWuyo3AUs4iHGurvkLqJLjHUbA6/OXYeIdE9VFhfnrmEVrgd+CrwZuDtw
k6osHluVxSeqsji7nvErIrJeGqEgY6dxCisySeHtoO7SOMwNc16DQaMI0jAyDjTjn6ULeoVAM8yt
jWsKtw/bW0f/6IMQuq6lFyhPJZ/HBo1zSMdCzCcfB21jPvl5HGyHmsMIhhAGX09/922oeSLetKvK
4l/GutOAvXLXkrgB8Aj8ysDSLOqOXp2jgBfmLmIRbwQek7uIDnkGcNPcRYiItMDv6M2x/WlVFlfm
LkhE2k0BrmShEHdZJim8hf5O1riDNJ31GnfEDloYKnTYxouCxaHtoJEG4T6cTa4fm4v2G24bh8dh
dm4cvsbBcgiR4w7guI6wjxn6Z/CmAW9af1jELA1942MJ25lJ9rsmuX8nwdE0L8AFeDYKcBvFWLc5
8MTcdbTcMbkLWI9HG+v2qsritNyFTLq6+/Z1uesQkU47Enhg7iIW8Td8WPtj4MiqLP6euyARmSyT
8kJeWkjjFJZk0sJb6L1xFHe1BmnHaizuPF1sNmw8MzfeXljIK4SmceA7Ry8YDeFnuuhYut10fMMU
g+fsxl3FJPtIu3Lj+yPeThwGTyfbZcDt51nYUZzOv52EMQpNPqV7z9xFSJ/n47ujZYXqeXy/yl3H
enzMWKe/aUfv1cDOuYsQkU57be4CIlcChwMvB24L3Kwqi2dUZfFlhbciMgrqwJWs1Im7XpMY3kJv
fEEIVWHhTNgQnMZjAFJxt2088zYOR9PAF3w3arrwWRx0puMZ4o/xqIQwiiFd4Cw2k2wjXcAt7rhN
F0ILt4u/N8fg+4nk9nMMDrdnWFhjW/2UhaMwmuINqOOzEYx1mwCvyl3HhDgUuFPuIhZxL/yYjINy
FzKpjHU7o0XjRCSzqix+aazLtft1wEn0umxPqsri+tz3iYh0h7oVJDt14g40qeEt9C9WFneUhmBx
sREG8SxY6M19jWe7ph2o8biBeNRBvMhYmCEb1xIWFZuJahrUuZqObIi3G+9/asD10y7eQSMd0sXR
0nA3DqjT+uLweyapofUhblUWl9Psxcz2yF2EAPBMYIfcRUyIpo8GOcBYt3XuIibYR4BNchchIgJ8
doz7Ogv4GPBo4MZVWdyrKou3VmVxvMJbERk3BbjSCApx+0xyeJuKg8cZFoaaafAZB5TpCIZ0vMF0
8nkadKazbePwNA6LZ+kFw2F/cXg8n2w7CN+LA9T0ONIF1+Kv487jdH5tPIYiDrDj0HbQuIT0fm27
po5RmEJzIrMz1q2hWadatlpVFmcCJ+euYz1uArw7dxGTqD5T6uG56xARqX14hNv+J/BV/JoGO1Zl
sUdVFvtXZXFoVRaX5T5wEek2BbjSGApxgW6Et2kHaPg6zKadTX6WhrRxYBluEweVM8l1B83Hjbtm
44B1Nrr9THL7OASG/hENgwLWUHs84iBdjC0NfuOO4TUsDKXD/RN3+4b7IQ13F5sVPCnhLfiFLJrq
qerCze6ZwE65i5gwB+cuYANeYKx7ZO4iJomxbgfG2+0mIrIhp+M7Y0fh5lVZPLkqi/+ryuL83Acq
IhJTgCuN0vEQtwvhLfR3jaYjAeJO0vT5aVAnaxr2piMLoH9xsbgzNQS0aaA8H90mFQfG0+vZ1lxS
y/q6b2cGfC8NmOP7JA18047gOKhOFzpLRzS03U+BpnZDzOAXVpqkwLw1jHU3Ad6bu44J9HWg6aeM
HmSsu0XuIiaBsW4W+AqwTe5aRESCqizmgW+NaPOPz318IiKLmaQX8jIhOhridiW8hV4QC/3Baryg
WLgMCizTGbfTybbjsHKGwXNj5+hfECzdDizs6g11pl2188m+Z6Ov43EIMLh7dy7abui8ja8bB7rr
6A9h4y7heGG4dDTFYmF1q9Wzxw7NXcd6PBAtZpbLewDNQx2yqiwuAo7IXccG3Aj4Wj1CQ1bnLcD9
chchIjLAt0e03VcZ65SRiEgjreTJ6ercRQtX5S5g1DoW4nYpvIX+cQNzyffj0DadeUt0u7SbNJ1z
G8wBa+mFo/H2ZwZsM130bFDd6WJgcdA6Q/9s3HC9QfXGl3X0wtl4HEIIjcPXa5LjWBfVEXfdxmMW
4hm98bYnRdNP6f6QsW6r3EV0ibHu7sBzc9cxwZr+mAO4Oz7ElxUy1u0DvCl3HSIii/gV8KcRbHcv
4Mm5D05EZJCVvJC/MnfR0o1/g46EuF0Lb6E/KE3HB8QBbDwuIe2onWL93axxyBp394brxmMV4jED
aR3TLBz3EN8u3jb0xi/EHbtpEBxvPw5d023Fc2/j+yMOZtPRCfFs4DgEDh/D9SfptP5jgAtyF7Ee
2wP/L3cRXVGf8v3J3HVMuAq4KHcRS/A/xroX5i6ijYx1ewLfZLJ+V4jIBKnHKHxhRJt/j7Fus9zH
KKtjrNvUWPcQY92LjHUvMNY9x1h3Z403kzZbSYB7Re6ipTv/BhMe4nYxvIXB815nk+uEsDIEkXFo
G4e3aRdsvM00oE3D0XR/IXCFXmg6F10/3H4dC8NjktvOJftNF1BL74f13UdxrfGxTQ24zTyL1xS2
N8MEqR8/h+SuYwNeaqx7UO4iOuJt+O4ZGZGqLK4FPpe7jiX6hLFO8wyXwVh3S+AHgM4cEJGm+wxw
7Qi2ezPgf3IfnKxMHdIeAfwL//vs48Cn8H+7nAKcZ6w70FinUVvSOgpw26lT/wYTGuJ2NbyF/nmz
IUyMg9rQSRvPfl1faDkT3WY6uk0IgAct5pWON4jD1Xifcfdq2Ebcvbsuun4aHMfikDquYTba5qAu
X5JtpWF1POM2nnUbdyHH25jEEQoAX8pdwAZMAV821m2Xu5BJZqx7MPCG3HV0xMeA63IXsQRTwCHG
ur1zF9IGxrptgR8Ceq4SkcaryuKfjO5vwNcZ63bIfYyydMa6LYx1BwInA/sCmyxy1ZsBLwNON9Y9
JnfdIsuxkhfyl+cuWrr3bzBhIW6Xw1tY2EE7aCGzeMGwePGuNCiFXpfsbHJ7Buxnhv6geD7Zbzyu
gOg261g47gH6w+L55HawsON2msGdt+lCbfG+09EOg0YtxEH1HP3HE9/H6dzhiVCVxe+BX+auYwMK
4CvGuonqgG6K+kXWl9Ep32NRlcVfgYNy17FEGwHfNdbdI3chTWasuwm+U2mX3LWIiCzDh0e03c2A
d+Y+OFkaY93GwPfwwexSM64C+Lax7uW56xdZqpUEuH/OXbR0899gQkLcroe30As547mxIXiMw8e4
Cze+zaBgMg4/w89m6O/wjUPVcN14Ru0cC2fShuvPJtsmuk4cQEN/F246Hzfu7E3n6Mbhclx3OI5w
DOuieuJtxF3EadAdh8DpuIpJ0YaFlR6AFgUaunru7VeAbXLX0jHvwz8ftcGWwJHGuoflLqSJjHW3
AI4H7pi7FhGR5ajK4neM7rXhszQCq/mMddP4N5UfsMJNfMhY9/DcxyGyFCsJcC8CLsldeIetA/6Y
u4hcWh7iKrztiUcXxHNa40A1DlDnoo9xQJl288bdjXG4Oc3CUQuwcAGw+PbpYl+D5tcuNt4hndEL
C59vQ/1r8Y/rELKG+yAeIREHvbP0h97xtsJIh3ThtUGdwZPmy8A1uYtYgrca6x6bu4hJUS9E8SHg
frlr6ZqqLP6ED87bYlPge8a6p+cupEmMdbcDfgbsnrsWEZEVOnCE2/6iZqU23uuBJ63i9lPAV411
N859ICIbsuwAt17x8azchXfYn6uyaMPcuZFpaYir8LYnDToX62BNu1hDeBk6UEMgC/3BajxKYDr5
Ot5/3I0aB8px2BvPw427g6cHfM6Ar8O207EH8T5n6R/PkHbPxpdBwez0ItuPF1NLx03MM2GqsvgX
PsRtuvBH4v1zFzIhXg/sn7uIDnsP7Xo+mcG/GH+1VqEGY929geOAm+auRURkFb4PnD2ibe8AfFq/
M5rJWHdP4O1D2NQWwPNzH4/Ihqx0MRsFuPmcmbuAJmhZiKvwtl86dzYNVuMFwgbNlY2/jrtw4wA2
7tRNO2fjjl8W+Xw+2d86+rt5p5Lrp3N30zm9cY2zLAxy49EJ8cf55Hphf3EoHAe+MyxcTC3+2aCF
4CbJAbRjxu9GwKHGur1yF9JmxrrnAu/KXUeXVWVxBvCt3HWswPuBrxnrtspdSA7Guilj3SuBo4Eb
5q5HRGQ1qrKYwz+vj8rjgaflPk7pZ6y7If5MoGGtL/ESY92a3Mclsj4rDXB/l7vwDvt97gKaoiUh
rsLbhdIAMV28DPpD0RDipl228YzcVNy9Ok//eIap5Drp4mTTA64Th6PxrN60uzcsuJZ2yMZ1DQqs
44XL0u7hWfqPIz72+P6L9xFvK8zPjbc5kaqy+APwjdx1LNFWwPeNdbfMXUgbGeseBXw6dx0CwP+j
XV24gQV+aazr1NzX+lTg7wIfZHJnootI93yR0TaZfcxYt3PugxSv7oj+DHCLIW52BzROSBpupQHu
93MX3mFH5C6gSRoe4iq8HSzunE2FsBEWLgIWfh5uF0YPxOFnCFpnk/2lHanxImEk+5nBn0azHXAz
YCf8qty7A7cFbgfsUX9vR/yppzfBz1dcQ3/nb7zvQYugxdcN4fB0vZ04nJ5Ptp2OXUg7cUn2H3f0
LnbfT4p35y5gGbYDjjbW7ZG7kDYx1j0OKFn53zAyRFVZ/Bb/wrmNdgFONNa9uF4EZaLVp5qeCjwy
dy0iIsNUlcX1wKtGuIstgW8b67bMfawCwAuAJ4xguwrppdFW+sfq6cA5uYvvoIuAE3IX0TQNDXEV
3i4u7jJNA9oQYMajCuIAdC7ZRtxBG48YiBcqi+fXxvsO+9gUuBV+9e1741cwvTs+qN2t/tlO+DB3
O2B74Ob193bFh7l3BO4J3Be4B3AH/Lu4s/QHqYt1/UJ/13D6/yYOeuP7Ku1enmVhMJyOmhjWaUaN
VJXFb4Dv5a5jGXYEjjfW/VfuQtrAWPdCfJf1RrlrkT6vBy7PXcQKbQR8DDjWWHf73MWMgrFua2Pd
p4Hj8c85IiKTqAKOHOH298KP39HZCxkZ6x6M/709CjvlPj6R9VlRgFsvZPbd3MV30OFVWazNXUQT
NSzEVXi7fqFjNu6gXSyQjbtG06A2HiOQLhSWBp5pZ++N8OHrPfGB687A1sAmqzy2aWBzYFvg1vhA
+E7403s2oz9kHjReYVCwnYaw6azdsL34viLZVxwOd0Hb5qLeGN+J+9DchTRVPbPzrcAn6c7/49ao
yuJC4E2561ilewOnGus+NCkdVsa6aWPds/CnFT8fPXZEZILVGcUrGe1Yn4cBH9aiZnkY6+4AfJPR
NaRcnPsYRdZnNaeLHZq7+A76bu4CmqwhIa7C26WJO1Dj4DWEkuH+C2HmLAsXP5ulf5TAoDm30At3
Q6h6L3yoenN89+0oTQE3wHfx3gP4r/rzG7Bw9EMa3KaLoKUzb9PvpYuXxWMT0mB7olVlcTLw49x1
LNNmwGHGumfmLqRpjHUbAZ8A3pa7FlmvT+JPz2+zGeAVwJnGuucb6zbOXdBK1G94PBDfcfsF/Jgf
EZGJV5+J9fkR7+ZFwMtyH2vXGOtuih8nOco3WbVgvDTaagLcnwP/zH0AHXI17Qskxi5ziKvwdmnC
804YczCX/DwEttB7d3V+wHXmo+0wYDsz+I7aXfGdVbfHjz/Iubro5vhu3DvjA90dWBi4hsB5Hf0h
7qCO3XRRsniebtzVnN4vXegaeHPuAlZgFvg/Y93njXWb5S6mCeoFQ44DXpi7Flm/+gyhF+WuY0h2
wC+S90dj3cva8nisg9uH48dt/QT/e0ZEpGveDFw54n18qJ7JL2NgrNsKPyLjpiPczfXAH3Ifq8j6
rDjArUOqw3IfQIf8qA4nZQMyhbgKb5cnDhpDeBmC23iUwnTydbqwVzxqIV74axY/5+9u+F/0TVyc
ZmP8jN274buj0i7cuPs2fFzsEo9QGNSdG9/v8ZiFiVWVxUn4ha7a6NnAyca62+QuJCdj3WPwHZ2a
D9wSVVmcCHw2dx1DdFPgQOBcY90bjXU3y13QIMa6zY11T8M/Xg7Dz3EXEemkeqzPO0a8myng68a6
/XIf76Qz1m2Nf12/54h39cWqLEYd/IusympDje/mPoAO0ciKZRhziKvwdnkGLeIVd9PGs2DXRddJ
Ry7EYW88J3cHfOCzM+1YsGtT4Lb4P0puVB93uB/iecHxAmxxUBvuk/Ax/Vm8cNmgrt1J9irg37mL
WKHbAr8w1j2ra3PWjHWbGus+DHwbP25E2uX1TN4ZWtsA7wTOM9b92Fj3dGPdFjkLMtbNGOseaKz7
IuCAgxn9i1sRkbb4X+CUEe9jBjjEWPfs3Ac7qYx12wBHAXcd8a7mgPfnPl6RDVltgPsTQF2hozcH
HJ67iLYZU4ir8Hb54q7Z+HT/eGZr3Jk7jQ9y5+nNwo0Drbn6ejfBjybYlXauUL8VfnXb2+NnO4X7
JISyIcyN77dwf8Yf007ceEZwl8JbqrI4H3h77jpWYTP8/MqfGOtunbuYcTDWPQz4PfDS3LXIylRl
cTGTO/JiCngQ8EXAGetKY90LjHW7juONFmPdTYx1jzfWfQI4F//3zdPx43lERKRWlcX1wFMZfVYx
BXzeWLd/7mOeNMa67YFjGc+bk5+ryuLs3McssiGzq7lxVRZXG+sq4Am5D2TCHVuVxaR1s4xFVRZX
Gesehe9gftCQN6/wduXiDtH56GM8EzceKRCeq+boDzLBd63ujA9AJ8GN68vf8S/Qw/+vqUU+xuMo
Qti7Lvp5uA/jxc+65EDgmUCbxxHsDfzaWPcB4F1VWbS1q3hRxrpb4P+tHp27Flm9qiy+baz7HPDc
3LWM0Gb4v3/D38AXGOuOwq8RcSZwFnBhvSr6stXz/navL3fCPw+ow1ZEZImqsjjLWPdK4FNj2N1H
6jMz3rvS533pqddA+DF+8edROxN4Ze5jFlmKVQW4tXcCj6d7ocA4vS13AW02ohBX4e3KDXquCMFj
fJ3QcRtGCoTvhwW+pvCnte5KM2fcrtb2wA2BM/ALMYTxB3HAHYSQNp6hG64bOnLD1536o7Iqi+uN
dS8CjsldyyqtwZ+a/hRj3euBr0/C84+x7gbA/vWxtWKhKFmyl+JnfN8+dyFjcjN8N+zTo+9dbqz7
A/An4HLgiuhyJX6hzS2Ty02BPYDtch+QiMgE+Azw8Poyau8G9jDWvbAqi6tzH3hbGeseBHwd2HoM
u7sOeNIkNkfIZFp16FGVxW/wc7dkNA6vyuKnuYtouyGPU1B4u3ohZAzmos/jhcvihbji+a2zwE74
zqRJDG+DTYE74Dty09EI8ezfdFGzMGpiesDHzr3ZVpXFscCXc9cxJDvin4PONNY921jXxnEhGOtu
bKz7f8Bf8AuNKLydMPWLV0t751APw1bAXer74bnAK4C3AAcAn8TPaPx/wKvxYyeeAtwfhbciIkNR
d8M+B/jHmHb5dOCEuoNUlsFYN1V3TP+Q8YS3AK+qyuLXuY9dZKmGFXy8Bbg298FMoDngdbmLmBRD
CnEV3q5eHDxCb9ZtHNIG8fXi0QF74LudumAWf/r/DvXX8X0UL04WLwwXd9mG7uYQ3k5y4L0+rwIu
zV3EEO0CfB44x1j3ktwLKi2Vse6mxroD8MHtm9EiZROtKoszmdx5uCIi0gJVWfwDH+KOy17AL411
D8l97G1hrNsM+BLwQcb3WuVw4GO5j11kOYby4KjK4jzgI7kPZgIdVJXF73MXMUlWGeIqvB2eWXph
4gz9p/bHHaXQ33m7KX4G4LjelW2KKXzH8S7R12FkAvSPVoi7cKEX3oYu3HCdTqnKwgEvyV3HCNwc
+ChwobHui/Wq9DOr3egwGes2N9Y91Vj3Q+A8fJiuRZc6oiqLL+MX4xMREcmiKovD8bP2x+VGwPeN
dW811q3JffxNZqzbCz8//ilj3O3fgWdpXrG0zTDf3XgPcEnuA5og1wBvzV3EJFphiKvwdnjiDtzp
6HvQH9bGlzn8bMDb0+1TrQvgtvRGJED/LPP4vptj4TiKRgV7GXwF+GruIkZkc/xpez8BzjXWvcdY
dzdj3TBm3S+bsW4LY92+xrqDgAvxXRUPprsd4F23P3Ba7iJERKTTXg18f4z7m8KvZXOyse7OuQ++
aYx1G9XjtH6BHxk3LtcBtiqLi3LfByLLNbQXUlVZXIIf3C3DcWBVFhfkLmJSLTPEVXg7XOnp//HC
W+E+jgPHaWBbfHCpd7D9TMU74AO7OKwdFIITXSde1KyT6nfZXwCcnbuWEbsZfvzOicC/jHWHGete
Yazb01g3kn9/Y90mxrr7G+v+n7HuePwbukcAzwBaMd5BRif6nety1yIiIt1UlcVa4InAb8a8673w
Ie4B9aiAzqsD7VPw47TG3Wzw9Kosjs99H4isxLAfLB/Dd1nsmPvAWu5fwPtyFzHpqrK4ylj3KOBQ
4EGLXE3h7fDF81jjz+fozWudohfwbgncig6e9r8eGwO7Ab+jF8rODbheGE8R379zS9zHRKrK4gpj
ncWHmxvnrmcMtqR/9eUrjXVnAfHlD8BFwBXAFYOe7+rF0rasLzvgFxCML7ugN1hkPaqyOM9Yty9w
LP7/kYiIyFjVfwca4CR660uMwzR+hNRjjXUvqMpiGAtrt46xbmvgDcDLyXNm4Ouqsvh67vtBZKWG
GuBWZXGNse5NwMG5D6zl3lmVxaW5i+iCDYS4Cm9HY3rA56HbNp5/Owdsgg8qFd4utCn+vjmL/o7l
eRaGtfHPuj5GgaosTjPWvRy/CnzXbAHcub4MZKy7Gh/mXlNff0sUzsoQVGVxav079wfARrnrERGR
7qnK4gJj3cOB4xj/TP5bAj+u1wV4c1UWv8h9f4yDsW5T4KXA68m3gO2ngffnvi9EVmMUp1J+GR98
ycocgRaEG6vo1M4q+vYXUHg7SmFBrRDUziXfBx8Y7cb4T6tpkxvgF7EKgW2QjlOIO54VhnufBsrc
RTTUpvixJTviFwxUeCtDU5XF0fiFSrRwiIiIZFGVxan4cQq5zkx7CH6swneNdeOc/zpWxrpZY91z
8ePL3ku+8Pb7wEu0aJm03dAD3PpB8Tz8MGpZnjOAJys0HL+qLK6qyuLh+K60PaqyeI7+HUYqjEgI
gWIIacM4gGn8O9SaE7Vh2+MXN4tHT0zTC3RDRzP0B+edFv2uOid3LSJdU5XFN4EX565DRES6qyqL
Cnhh5jIeBfzaWPc1Y90dc98nw2Ksu6Gx7hXAmcBngZtmLOcU4In1DGSRVhvJYiZVWVwNPBr4W+4D
bJFLgEdWZXFZ7kK6rCqLX1VlcVbuOiZcCBHD/Nt07u0M/pf8jXIX2iI74d/RDh3NYUG4EOamHc56
9xmoyuJy4JHA5blrEemaqiw+Cbw1dx0iItJdVVl8Fh/i5v7b+InAr4x1Jxvrnmusa+UCsMa62xrr
PglcAHwIv45JTqcAD6nK4orc943IMIxsNfKqLP6GD3GvyX2QLbAOeEJVFuoEky6Yov+U/hDkUn9+
I3xXqSzdFP4PpDX07stZ+mfjhiB3BnXg/kdVFmcAjwWuy12LSAe9A3hn7iJERKS7qrL4NPAMmrHQ
713xHat/M9Z90lh3J2Ndo/9uN9YVxrrnGeuOwi+w/ELGP1t4kJ8BD6rK4l+5CxEZlpEFuAD1UO5n
5z7IFnhZVRZH5i5CZEzCO9whSIy/3hLfTSrLtwbYlV5wG2YLp/NvG/1HYA718+/TyN99IdIpVVnM
V2XxZuA1uWsREZHuqsriS4AFrs9dS21LfBD6S+DPxrpPGOuMsa4R4+WMdTsZ615hrPsp8HfgM8AD
ctcVOQrfeauzm2WijHxxoKosvmqsux3whtwH21CfBj6RuwiRMQozbuN5t6ET9+aM+I2lCbcZcBPg
IvoXiYtnDMsAVVmUxrptgI/lrkWka6qyOMBY9w/g8/QvyCgiIjIWVVl8y1j3aODbwMa564ncAvjv
+nKNse5o/KJcpwC/qcri36PcubFuBtgDuFt9uQdw+9x3ynocATy+HuspMlHGtbr7m4Hb4od0S8+x
wP5aDVE6KAS489HXN6AZp9u03fZAOFUoHpkQB7rqwh2gKouPG+sK/O8sERmjqiy+aKy7GCiBTXPX
IyIi3VOVxRHGuocB36OZr0s2AfatLwDzxro/AKcCpwG/xq9D5ICLlrMot7FuK+Bm+IaamwG7AP+F
H+uwZe4DX6Jv4ReF12g0mUhjCXCrspgz1j0Z+AJ+QLf4d832q8qiKadpiIzLPH7u83TyvR1yFzYh
ZvAh7l/pjUyIg3JZv7cCBfD83IWIdE1VFocb6x4EHI4WshQRkQyqsjjKWHdf4Lv4MLPJpoDd68uT
kp/NGesuwoe5/wDW0ltAeib6/Ib1cbYlpF3MAcDrlxNai7TN2F7MV2VxFbAf8KbcB90AHwAeoZks
0lHhj4Xw+TT+tP8mnarUduH+jE9FDiGuum/Xoz4j4kXA13LXItJFVVmcANwbODd3LSIi0k1VWfwK
uDN+lmpbTQPb4scdPBB4CLAPsDdwP+A+wD2B29Du8PZqfGPcaxTeyqQbazdWvVjFu/Arfo90VktD
XQc8syqLV+vJRTouDXG3y13QhJmid5+GEQrhovmSG1A/Pz8NOCR3LSJdVJXF6fjTNo/OXYuIiHRT
VRb/xIee789diyzqXOAeVVmo8UI6IcvptFVZfAf/bs9fct8BY/QP4AFVWXwxT/6Y9wAAGohJREFU
dyEiDRDmsU7h3xlek7ugCXQj/Jys+L7WImZLVJXFWuDpwIG5axHpovqF8z7A2+mdQSAiIjI2VVms
rcritcATgCtz1yN9fgLcpSqLX+cuRGRcss1DrMriN/juiuNz3wljcBpw1/q0QJGui0/h3wjYJndB
E2z7+uMUfub5uBaunAhVWcwBrwRel7sWkS6qymJdVRZvAx6En+EnMukuxc+pHCY9diZYfdbQv1a9
oX76P5OoyuKb+OzizNy1CPPAe4F9q7K4OHcxIuOUdUGbqiz+gZ/H8vncd8QIfQu4d1UW5+UuRKQh
rqMX4m6DFtYapS3pzbSKu2+vyV1YW9Sjf94HPBu/+J6IjFlVFkcBewFH5q5FBjof+GPuIiZBffbH
n4a4yWvx/z4y2c4e8vbOyX1ATVSVxRnAXYCP566lw84G7lOVxevr50uRTskenFRlcR3wPPx8mUlq
fz8LP+v3CVVZdHHer8hirsEHuNNolfFxuDG95/qwaJwC3GWqyuL/gMeg+04ki6osLsT/rfhmht+h
KCt3KHBHQGeZDc9ZQ9zWOfXZJDLZhvl/5m9VWWhUwCKqsvh3VRYvwS+2qW7c8ZkHPgjsWZXFz3IX
I5JL9gAX/tPh9CPgTviFY9o8G/dC4IXA7aqy+E69ormI9FyDf+65AQ15DppwW+Dv53h0xdW5i2qj
qiwOw6/cq1MLRTKoRyq8E7gbfjyV5HMxfk74Y3QK69ANc72Mg3IfjIyF/s+MWR0i3hF4B3pTcdTO
Au5VlcWrqrLQaxjptEaFJ1VZzFVl8WVgD+BVwCW5a1qGK4G3ALtWZfFptfSLLOpa/LuoW+QupCOm
gc3oBbjqwF2Fqix+DtwVODF3LSJdVZXFr/CPw5cCl+eup2Pmgc8Au1dl8SU1KozEdxnOKeyX4/+t
ZPIdDfxqCNu5Dvho7oNpi6osrqnK4i34JrSTc9czgdbiZ93esf77W6TzGhXgBvWT4QeBWwHvxwc+
TbUW+Bhwq6os3qFTTkQ26N/4554tV7shWbIb0Atw52j2c2rjVWVxPnBf4AO5axHpqnpl8I8CuwOH
5K6nI04F7lGVxQvUdTs69aJUbxjCpt5VlYXe4OiA+o2U19O/3sFKfKgeVyPLUJXFb4F7Ai8G/pm7
ngnxdeDW9axbdd2K1BoZ4AZVWVxSlcVrgV2BA2jWQPW/AB/BP7HsXy/IJiIbdjWwOTCTu5AO2RIf
4M4AV6ljavWqsri+KotXA49g+Ks/y2j9muZ1UOusnRWqyuLCqiyeCjwAOCN3PRPqMuAlwF2rsjgp
dzFdUJXFN4C3rmITB+FfO0lH1OMIX76KTXwbeGPu42iresTPJ4Bb4sP0Np1J3CRH4X/XPKkqiyZl
PzJ8w/7btxN/Szc6wA2qsji/KovXALsBt8W/K53jNIXTgLfh593sXJXFy/TEIrJsF+IDXBmfGfwY
hXngotzFTJKqLA4H9kIL+LTFp4C7A8fkLiTx19wFtF1VFscAe+KDRr2pPhzX488y260qi4/XnaEy
Pu8ADlzB7b4BvEBv1nZPfVbC2/F/7y3HD4CnacG71avK4sqqLN4L7Iz/t7gid00tcSp+odIHVWVx
Su5iZCzOHfL2/pz7gMZhNncBy1H/IXJ6fXmPsW4H4JHAo4AHAmuGvMt1wLH4FXYPrcqizYuriTTF
34GtchfRQVvgRydckLuQSVOVxfnGuvvj/1B/LS15c7RjLgVeWJXF1wGMdefnLijRtHpaqSqL64GP
G+sOAp4HvBrYIXddLXQN8FnggHpkjGRQv+55hbHuUPws2103cJML8W9gfFvhbXdVZfE2Y933gc8B
t9vA1f8FvALQPOshq8riMuBtxrqP4n8X7Y9vppB+J+NHkn1LbyB0ztkN314jtSrATVVl8Td8N82n
jHVb4WcS7oGfhxY+brPEzf0Lv8LhmfXHs4CfVmWhU2NFhmtThv9mi2zYVvjnub/nLmQS1cHRG4x1
h+GDj9vmrkn+41DgRfXfDEHTQqmm1dNqVVn8GzjQWPdJ4BnA6/DdULJ+VwKfwM/BdLmLEa8qi2OM
dXcA9gEM8GD8bHvwY6mOAirg+5p5KwBVWZxkrLsTvsHJAPsCN6p/fB3+LJQKqKqy0Kn+I1TPC3+d
se4A4Ln4NxdvlbuuzK7Dz7j9WFUWWvytu87Ar40zrDNzO9G5PbX6TTSbsW4j/PzH+DKFP53hP5eq
LLSoj8gYGOveDrwldx0d9WfgPVVZfDZ3IZPMWLcGeBl+5I7GheRzLrB/Peaij7Fud/wbtk3xvKos
Ppe7iEllrJsF9sPPJbx17noa6BL8ug4fWWnjgrHuYOBpuQ8kckFVFjfPXYSISMxYN42f2f4C4DG0
vKFumf4KfBL4rNYPEgBj3YeBlw5hU+cAe3Rh1NPEP2FUZXEdcHF9EZH87pG7gA7bAs3aHLm6G/cD
xrqvAf8LPD53TR1zHfA+/JsVi61c/Af8OKbb5C4WP67p0NxFTLKqLNYCXzLWfRnfkfZC/AKEG+Wu
LbOfA18AvlKVxVWr3JYWJhUR2YB6TMCRwJHGugJ4JpPdlXsV8H3gK8Bh9d/IIsH/Ai9m9X9DfKgL
4S10oANXRJrFWHceoK6YPC4F7luVxW9zF9IlxrqH4BcC2iV3LR3wY+AlVVn8YUNXNNa9Cb9IUG4/
qMpi39xFdI2x7kaABZ4O3DN3PWN0LvAl/MzLoc2Lq+e0PjL3wUXOq8riFrmLEBHZEGPdFHBX4OH4
kRd3yl3TKl0CfA/4DvCj9byZLoKx7uX4IHelfgQ8vCtvDkx8B66INIexbnMU3ua0Bj/fW8aoKosf
Gutujz9F6A30ZhfK8PwBf3r8d5axEMtXaUaA+5XcBXRRPffx08CnjXW7AE/FjwC4Ze7aRuAy4BvA
wcDPRrRQzBa5DzKhld9FpBXqv1tOri9vqRdq3xcf5u5D855fB/kj8EN8aHtsV8I0GYoP4zvQX7KC
2/4WeEKX/r+pA1dExsZYtxdwau46Ouyqqiw0kzUjY90N8X+gvALYOnc9E+AM4D3AV+vT5JfFWPdD
/IJAuThg16osFDY1QN0FdQd8F9TewL2AjXPXtUK/Bo4GjsC/mL5ulDsz1p2M7yBripOqsrh77iJE
RFbDWLcx/iyRewF3BPYi/xuNl+HD5pPqy8maaSurYaybwTdivJmlj7f6Nn6R4k4tuqoAV0TGxlj3
ROBruevouE2rsrgmdxFdZ6zbAr+AxauA7XLX00KnAe/Ed9yuuJvQWLcr/t37XCHdflVZ6DmxoYx1
m+Dntj8AH+jejeaevXYmcFR9ObYqi4vGuXNj3RnAHrnvhMhPqrLYJ3cRIiLDZqy7AbAnvUD3DsBO
DL8x4CLggvryR3wTzknAH0Z0Jod0nLFuD/w4hQex+N9bpwNvrsri27nrzUEBroiMjbHuZcCBuevo
uB2rsjg/dxHi1QHRs4HXAjvmrqcFfo4Pbr+/jFEJ65VxFu4PgIcN6zhk9Oo3Xu5aX24L7F5fbjjG
Mq4FzsaPwzkD+CW+++lvGe+XKfzIgiad4fGdqiwem7sIEZFxMdZtBGwDFMllG2BTYK6+rBvw8XLg
b/iw9nzgb5pdK7nUZyw+GLgLvczyL0BVlcWfc9eXkwJcERmbBi0a1GW3rcri9NxFSL/6j+7H4scr
3Ct3PQ1zPX6m2kfx8zuHGnjW9/0vgduN8Zj+Ddy+63+EToI6vNwGH+TuUX+8ObA9ft71lvVlC2CT
9WxqLT4EvQK4sv54IfBXeoHtmfjFuRq10rKx7qb4F/1N8qWqLJ6euwgRERGRYWnqaWAiMpm2zF2A
6N+gier5lF8Dvlaf1v+0+rJT7toyOhG/6FJZlcXFo9pJVRbXGesehp8XeqsxHNdVgFF4OxnqNxT+
UV+OW991jXVr8EHulsAMMI8P868Arm1xN/ZuuQsY4PLcBYiIiIgMkwJcERknhYf56d+g4aqyOBu/
CvHbgHvjg1wLbJW7tjH4C/AlfPfcH8a106oszjfWPYDRh7hX4ccmHDuuY5PmqFdJvqS+TJLdcxcw
wIW5CxAREREZJgW4IjJOW+QuQBTgtkW9QMRPgZ8a614KGOBRwL7AjXPXN0TnAIfjxyQcn2thjCjE
/SFw6xHs4hLgMQpvZQI1cexL00Y6iIiIiKyKAlwRGadGze3rqLW5C5DlqxeS+CbwTWPdNH5e6971
5X60qzv3r8CRwFHA0VVZnJe7oKAOce8IvA54A7DRkDZ9CPDKqiz+kfsYRYapngF8/9x1DHBW7gJE
REREhkkBroiM0xW5CxD9G7Rd3aH6m/pyoLFuFrgTPsy9D37F1m1z1xk5BzgFOAYf2p7T5FmfVVlc
C7zdWPc14BP4+3WlzgZeXJXFj3Mfl8iI3Aq4We4iBhjbCBYRERGRcVCAKyLjpPAwP/0bTJiqLNYC
J9eX99YdcTfBz6VML7diNL/7r8J3vKWXP1RlcWXu+2glqrI4C3igsW4PYL/6susSbnoRUAJfBU7I
NRJCZEz2yV3AAP8a5cKHIiIiIjkowBWRcVJ4mJ/+DSZc3d36z/pyfPwzY90a4ObADsDW+JnIgy6b
A1P4sSdXAFfWH9PLP/AjEf7e5K7aVd6fZwJvrReVuz2wW30f3hzYHrgYP2/zfOBc4OR6sSqRLtgv
dwEDqPtWREREJo4CXBEZp8tzFyAKcLusDhb/VF9kGeqAOoyuEOk8Y90t8GNbmubs3AWIiIiIDNt0
7gJEpFMas1hRR12L75gUERFZrSfnLmARepNFREREJo4CXBEZpzNzF9BxZ1dlsS53ESIi0m714onP
zV3HIk7JXYCIiIjIsCnAFZFxOhfQbMh8zspdgIiITAQL3DJ3EQNcj1/QUURERGSiKMAVkbGpymIt
cE7uOjpMAa6IiKyKsW4KeF3uOhZxYlUWV+UuQkRERGTYFOCKyLj9PncBHXZ67gJERKT1HgHcPncR
izgmdwEiIiIio6AAV0TG7Ye5C+ioeeDHuYsQEZH2MtZtDHwwdx3rcUzuAkRERERGQQGuiIzbYfgw
UcbrZ1VZ/CN3ESIi0mqvAnbJXcQirgN+nrsIERERkVFQgCsiY1WVhQNOyF1HB303dwEiItJexrpb
AG/MXcd6HF+VxdW5ixAREREZBQW4IpLDobkL6CDd5yIisiLGuhngIGDT3LWsx2G5CxAREREZFQW4
IpLDd3MX0DG/r8rinNxFiIhIa70JuH/uItZjHvhW7iJERERERkUBroiMXVUWZwNn5K6jQ9R9KyIi
K2KsewDw1tx1bMAxVVmcn7sIERERkVFRgCsiuXw3dwEd8t3cBYiISPsY63YFvg5M5a5lAw7JXYCI
iIjIKM3mLkBEOutQ4PW5i+iAvwG/zF2EiMiGGOumgJ2Au+JnrU7hmw3WAr8GfleVxbrcdXaFsW47
4IfANrlr2YBr0fgEERERmXAKcEUkl18AFwA3y13IhPtOVRZzuYsQERnEWLcp8FRgH+BewA7rufqV
xrqTgePxgd1vq7KYz30Mk8hYtxXwfWDn3LUswWFVWVyauwgRERGRUWr66VAiMsGMdf8NfCJ3HRPs
OmD3qizOzV2IiEjMWLcF8ALgVcB2K9zM6cDXgK/Vs9VlCIx12wAVvhO6DR5RlcXhuYsQERERGSUF
uCKSjbFuDfA7YLfctUyoD1Vl8T+5ixARiRnrngocCNx4iJv9JT7MLauyOC/3MbaVsW5n/NiEXXPX
skTnArtotIaIiIhMOgW4IpKVse5xwDdz1zGBLgNuVZXFxbkLEREBMNbNAu8HXjHiXf0M+Crwzaos
XO7jbgtj3Z2Bw1l5R3QOL6vK4iO5ixAREREZNQW4IpJVvWjNCcDdc9cyYV5XlcX7chchIgJgrLsx
8HXggWPc7RxwFD7M/U5VFpfkvh+aqP49vD9wALBR7nqW4SLgFlVZXJW7EBEREZFRU4ArItkZ6+4D
/DR3HRPkAmC3qiyuzl2IiIixbiPgaOCeGcu4HvgBfszC96qyuDL3/dIExrqtgc8Dj85dywq8uSqL
d+YuQkRERGQcFOCKSCMY674HPCJ3HRPi2VVZ/F/uIkREAIx1HwNenLuOyNXAYfgw9/tVWVyTu6Bx
M9ZNA0/Dj7TYNnc9K3AlsKO6qkVERKQrFOCKSCMY624D/BaYzl1Ly/0O2EsLuohIExjrngEclLuO
9bgc+A7wbeAnXTgd31i3F/Ax4F65a1mFA6qyeE3uIkRERETGRQGuiDSGse5zwHNy19FyD6/Kospd
hIiIsW5b4M/AZrlrWaJr8DNzDwcOr8ri/NwFDZOx7q7A6/HjEtr8GuDf+EU6tUCdiIiIdMZs7gJE
RCKvBu4D7Ja7kJb6NHBE7iJERGovoz3hLcAmwMPqyyeMdb/Gj1o4AvhlVRbX5S5wuYx1a4CHAC9n
vAvIjdIHFN6KiIhI17T53XcRmUDGut2Bk4Ab5K6lZY4F9qnK4vrchYiIGOtuAJwHbJW7liG5DjgV
ODm6nFOVxVzuwlL1fNu7AU8BngjcJHdNQ/QX4DZdGHUhIiIiElOAKyKNY6x7MPB9NA93qf4M/FdV
FhflLkREBMBY91rgvbnrGLHLgF8AvwfOjS9VWVw6riKMdZsAewD3BR5Qf9w6950zIo+pyuK7uYsQ
ERERGTcFuCLSSMa6lwP/m7uOFrgSuEdVFr/LXYiISGCs+zlw99x1ZHQpPsy9ALgCP7f1qvpj/PnV
wPwStrcG2DK63AC4JX7k0C3oxt/0PwAeVpXFUu4vERERkYnShT/2RKSFjHVTwOeAZ+eupcHmgUdX
ZfG93IWIiATGuo2By4GNctciE+M64HZVWZyduxARERGRHHR6sog0Ut1h8yLgZ7lrabA3KrwVkQa6
EwpvZbjer/BWREREukwBrog0VlUW1wKPxS+EI/2+wuTPlxSRdury6AQZvt8B78xdhIiIiEhOCnBF
pNGqsvgH8CDgD7lraZCvAM/RHEARaahJXUBLxu8a4Mn1G7oiIiIinaUAV0Qarz5t8m7AD3PXktk8
8HrgqVVZXJO7GBGRRejNJRmWl1Vl8dvcRYiIiIjkpgBXRFqhKotLgYcDB+auJZMr8QuWvVedtyLS
cHqOkmH4OvDZ3EWIiIiINIECXBFpjaos1lZl8QrgOcD1uesZo3OBe2rBMhFpCQW4slp/Ap6vNyxF
REREPAW4ItI6VVl8Adgb+GfuWsbgWOCuOoVURFrknNwFSKtdCzyxKovLcxciIiIi0hQKcEWklaqy
OB64K/Cb3LWM0KeBB1dlcVHuQkREluH43AVIqz2vKotTchchIiIi0iQKcEWktaqy+Atwd/zCXpPU
qfN74BHAf1dlcV3uYkRElukvwF9zFyGt9JaqLL6UuwgRERGRppnKXYCIyDAY624MvBF4MbBR7npW
6K/AW4AvVmWxLncxIiIrZaz7GvDE3HVIq3wCeInm3oqIiIgspABXRCaKsW4n4J3AU3LXsgyXAe8F
PlKVxVW5ixERWS1j3T7Aj3LXIa3xTeBJevNSREREZDAFuCIykYx1dwTeB+yTu5b1uA74OPCuqiwu
zl2MiMiwGOumgJ8Dd8tdizTe0cC+VVlcm7sQERERkaZSgCsiE63uAnsx8BBgk9z11P4OfAc4oCqL
c3MXIyIyCsY6Axyeuw5ptJOAh1RlcVnuQkRERESaTAGuiHSCsW5zfDfuo/ALhN14zCWcDhwKfBc4
pSqLudz3iYjIKNVduD8D7pG7FmmkE4CHKbwVERER2TAFuCLSOca6WeCewKPry84j2M08/sXpocCh
VVn8Ifdxi4iMm7Hu5sApwLa5a5FGOQw/81Zz30VERESWQAGuiHRa3SF2O+B+wO7RZcdlbOY64Gzg
rPpyOvDjqixc7uMTEcnNWHcf4ChgNnct0gifB15YlcXa3IWIiIiItIUCXBGRAYx1mwHbAFsml+uB
K4ErosuFWjlbRGRxxroXAJ/KXYdk9y7gzVVZzOcuRERERKRNFOCKiIiIyMgZ6x4DfBH/Zph0y1pg
/6osFOKLiIiIrIACXBEREREZC2PdHsB3gD1y1yJj4/Dzbo/JXYiIiIhIW03nLkBEREREuqEqizOB
uwHvBi7PXY+M3I+BPRXeioiIiKyOOnBFREREZOyMdTcAXgS8Aj9zXCbHOuBNwPurspjLXYyIiIhI
2ynAFREREZFsjHWbAPsCTwIeAWyauyZZlfOB/aqy+FnuQkREREQmhQJcEREREWkEY92W+BD3ScBD
gTW5a5Jl+TzwP1VZXJa7EBEREZFJogBXRERERBrHWHcj4LH4MHdvtHZDk/0aeLG6bkVERERGQwGu
iIiIiDSasa4AHg/sB9wrdz3yH5fhZ91+qiqLtbmLEREREZlUCnBFREREpDWMdTsCFt+Ze+fc9XTU
PPBF4LVVWfwjdzEiIiIik04BroiIiIi0krFuN+CJ+M7cW+eupyMOBd5SlcVvchciIiIi0hUKcEVE
RESk1Yx1U8Dt8EHuk4Cdc9c0YdYBXwHeV5XF73MXIyIiItI1CnBFREREZGLUYe5/AY8GHo4PdmVl
rgE+D3ygKotzcxcjIiIi0lUKcEVERERkYhnrdgYM8Ajg/sBGuWtqgbOAzwEHVWVxUe5iRETk/7d3
x6pRhGEYRh+iIEStBLcQJYWNvUS8AKvt9woFYWu3FATbqLUWopVsYSFRRKKJxawYUQKLJiPuOfAx
3c/LVMPLx/zAplPgAgCwEaaz5eXqXsNm7rS6Onamf8iyelDdr54t5pOjsQMBADBQ4AIAsHGms+VW
dbu62/DLhd3q5ti5zti76mFDcftoMZ98GTsQAAC/UuACAEA1nS2vNJS6u8fmf9rS/Vg9qR6vZm8x
n3wdOxQAACdT4AIAwG+sLkS7Xt2pblU7x+ZGdW7sjCc4rF5Xz6u9hsL26WI+ORg7GAAA61HgAgDA
mqaz5fnqWj+XujsNhe+l6mK1vXp+n7/17X1Yfaj2q/fV2+pNw+VjL6qX1avFfPJ57PcEAMCfU+AC
AMApW23zXuhHqbtdba1xxEFDYbtffXLJGAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
AACcpW8U2g5mQniRZAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMi0wNC0wMVQwNDoxODoxOSswMDow
MC4eg+4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjItMDQtMDFUMDQ6MTg6MTkrMDA6MDBfQztSAAAA
JnRFWHRpY2M6Y29weXJpZ2h0AE5vIGNvcHlyaWdodCwgdXNlIGZyZWVseaea8IIAAAAhdEVYdGlj
YzpkZXNjcmlwdGlvbgBzUkdCIElFQzYxOTY2LTIuMVet2kcAAAAidEVYdGljYzptYW51ZmFjdHVy
ZXIAc1JHQiBJRUM2MTk2Ni0yLjFrnBT5AAAAG3RFWHRpY2M6bW9kZWwAc1JHQiBJRUM2MTk2Ni0y
LjGFZP48AAAAAElFTkSuQmCC" />
          </svg>
      </div>
      <div class="na-right" style="height: 60px;width: 200px; float: right; margin-right:60px;text-align:center;">
        <div class="signUp" style="margin-right:30px;line-height: 60px;display:inline-block;color: #4B56E9; font-weight: bold">
          <router-link  to="/signup">
           SIGN UP
          </router-link>
        </div>
        <div class="login" style="height:60px;display:inline-block; color: #4B56E9;font-weight: bold">
          <router-link  to="/login">
            LOGIN
          </router-link>
        </div>
      </div>
    </div>
    <div class="header py-7 py-lg-7 "></div>
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Bg2 from '@/assets/background2.svg'
export default {
  name: "auth-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
      bg2: Bg2,
    };
  },
};
</script>
<style>

</style>
